import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Icon } from 'semantic-ui-react'
import { RelativeTime } from '@airmedia/react-time'
import { TEXT_COLOR_LIGHT } from '../../styles/variables'
import * as taskStatuses from '../../constants/taskStatuses'

const getIconProps = status => {
  switch (status) {
    case taskStatuses.IMPORTED:
    case taskStatuses.IMPORT_PAUSE:
    case taskStatuses.PAUSE:
    case taskStatuses.PENDING:
    case taskStatuses.SYNC_PENDING:
      return { name: 'wait', color: 'yellow' }
    case taskStatuses.IMPORTING:
    case taskStatuses.RUNNING:
    case taskStatuses.SYNCING:
      return { name: 'spinner', color: 'blue', loading: true }
    case taskStatuses.IMPORT_FAILED:
    case taskStatuses.FAILED:
    case taskStatuses.SYNC_FAILED:
      return { name: 'warning circle', color: 'red' }
    case taskStatuses.COMPLETED:
      return { name: 'check circle', color: 'olive' }
    case taskStatuses.SYNCED:
      return { name: 'check circle', color: 'green' }
    case taskStatuses.CANCELED:
      return { name: 'cancel', color: 'red' }

    // no default
  }

  return { name: 'frown outline' }
}

const TaskStatus = ({ className, status, updatedAt, mode }) => (
  <div className={`${className} ${mode}`}>
    <div className="status">
      <Icon {...getIconProps(status)} />
      <span>{taskStatuses.translate(status)}</span>
    </div>
    <div className="timestamp">
      <RelativeTime date={updatedAt} />
    </div>
  </div>
)

TaskStatus.defaultProps = {
  updatedAt: null,
  mode: 'wide',
}

TaskStatus.propTypes = {
  className: PropTypes.string.isRequired,
  status: PropTypes.oneOf(taskStatuses.list()).isRequired,
  updatedAt: PropTypes.string,
  mode: PropTypes.oneOf(['inline', 'wide']),
}

export default styled(TaskStatus)`
  .timestamp {
    font-size: 1em;
    color: ${TEXT_COLOR_LIGHT};
    white-space: nowrap;
  }

  &.wide {
    margin-bottom: 1rem;
  }

  &.inline {
    display: inline-block;

    .status {
      display: inline-block;
    }

    .timestamp {
      display: inline-block;
      margin-left: 1.25rem;
    }
  }
`
