import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Icon, Popup } from 'semantic-ui-react'

class ConfirmPopup extends Component {
  state = {
    opened: false,
  }

  open = () => this.setState({ opened: true })

  close = () => this.setState({ opened: false })

  handleConfirm = () => {
    this.props.confirm()
    this.close()
  }

  render() {
    const { className, children, extraButtons, header, ...props } = this.props

    return (
      <Popup
        open={this.state.opened}
        onOpen={this.open}
        onClose={this.close}
        on="click"
        {...props}
        className={className}
      >
        {header && <Popup.Header content={header} />}
        <Popup.Content>
          {children}
          <div>
            {extraButtons && extraButtons(this.close)}
            <Button
              basic
              floated="right"
              size="small"
              negative
              onClick={this.close}
              content="Отмена"
            />
            <Button
              basic
              floated="right"
              size="small"
              positive
              onClick={this.handleConfirm}
              content="Продолжить"
            />
            <Icon name="close" size="large" link className="close-cross" onClick={this.close} />
          </div>
        </Popup.Content>
      </Popup>
    )
  }
}

ConfirmPopup.defaultProps = {
  extraButtons: null,
  header: null,
}

ConfirmPopup.propTypes = {
  className: PropTypes.string.isRequired,
  trigger: PropTypes.element.isRequired,
  extraButtons: PropTypes.func,
  header: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  confirm: PropTypes.func.isRequired,
}

const StyledConfirmPopup = styled(ConfirmPopup)`
  position: relative;

  .close-cross {
    position: absolute;
    top: 5px;
    right: 0;
    opacity: 0.4 !important;

    &:hover {
      opacity: 0.9 !important;
    }
  }
`

export default StyledConfirmPopup
