import { createSelector } from 'reselect'

const getRequestErrors = state => state.requestErrors

const getErrorTypes = createSelector(
  getRequestErrors,
  errorsByType => Object.keys(errorsByType),
)

export const getRequestError = createSelector(
  getRequestErrors,
  (state, type) => type,
  (errorsByType, type) => errorsByType[type],
)

export const hasError = createSelector(
  getErrorTypes,
  (state, types) => types,
  (errorTypes, types) => types.some(type => errorTypes.includes(type)),
)
