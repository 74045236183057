import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import { createProjectApiHandler } from '@airmedia/projects-embed'
import { resolveAccessToken } from '../api/accessToken'
import rootReducer from './rootReducer'
import rootSaga from './rootSaga'
import { rollbarMiddleware } from '../rollbar'

const configureStore = preloadedState => {
  const sagaMiddleware = createSagaMiddleware()

  const store = createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(
      thunk,
      createProjectApiHandler(() => resolveAccessToken),
      sagaMiddleware,
      rollbarMiddleware,
    ),
  )

  sagaMiddleware.run(rootSaga)

  return store
}

export default configureStore
