import { handleActions } from 'redux-actions'
import { setFalse, setTrue } from './actionHandlers'

export const makeBooleanFlagReducer = (requestType, successType, failureType) =>
  handleActions(
    {
      [requestType]: setTrue,
      [successType]: setFalse,
      [failureType]: setFalse,
    },
    false,
  )
