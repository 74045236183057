import React, { Component } from 'react'
import { generatePath } from 'react-router'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import memoize from 'memoize-one'
import { fetchProject, getProject } from '@airmedia/projects-embed'
import * as routes from '../../../constants/routes'
import Breadcrumbs from './components/Breadcrumbs'

const mapStateToProps = (
  state,
  {
    match: {
      params: { projectId },
    },
  },
) => {
  const project = getProject(state, projectId)
  return {
    projectName: project && project.name,
  }
}

const buildIndexItem = params => ({
  link: generatePath(routes.INDEX, params),
  content: 'Список проектов',
})
const buildTaskListItem = (params, projectName) => ({
  link: generatePath(routes.TASKS_LIST, params),
  content: projectName || 'Список задач',
})
const buildTaskDetailsItem = params => ({
  link: generatePath(routes.TASK_DETAILS, params),
  content: `Задача №${params.taskId}`,
})

class BreadcrumbsContainer extends Component {
  breadcrumbItems = memoize((path, params, projectName) => {
    switch (path) {
      case routes.TASKS_LIST:
        return [buildIndexItem(params), buildTaskListItem(params, projectName)]
      case routes.TASK_NEW:
        return [
          buildIndexItem(params),
          buildTaskListItem(params, projectName),
          {
            link: generatePath(routes.TASK_NEW, params),
            content: 'Новая задача',
          },
        ]
      case routes.TASK_DETAILS:
        return [
          buildIndexItem(params),
          buildTaskListItem(params, projectName),
          buildTaskDetailsItem(params),
        ]
      case routes.TASK_CAMPAIGNS:
        return [
          buildIndexItem(params),
          buildTaskListItem(params, projectName),
          buildTaskDetailsItem(params),
          {
            link: generatePath(routes.TASK_CAMPAIGNS, params),
            content: 'Список кампаний',
          },
        ]
      case routes.LOG_EVENTS:
        return [
          buildIndexItem(params),
          buildTaskListItem(params, projectName),
          {
            link: generatePath(routes.LOG_EVENTS, params),
            content: 'Журнал изменений',
          },
        ]
      default:
        return [buildIndexItem(params)]
    }
  })

  componentDidMount() {
    const {
      projectName,
      fetchProject,
      match: {
        params: { projectId },
      },
    } = this.props

    if (!projectName && projectId) {
      fetchProject(projectId)
    }
  }

  componentDidUpdate() {
    const items = this.getBreadcrumbItems()
      .map(item => item.content)
      .filter(item => !!item)
      .reverse()

    items.push(this.props.baseTitle)

    document.title = items.join(' | ')
  }

  getBreadcrumbItems() {
    const { match, projectName } = this.props
    return this.breadcrumbItems(match.path, match.params, projectName)
  }

  render() {
    const items = this.getBreadcrumbItems()
    return <Breadcrumbs items={items} />
  }
}

BreadcrumbsContainer.defaultProps = {
  baseTitle: 'AirMedia.RS-Reshuffler',
  projectName: null,
}

BreadcrumbsContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    params: PropTypes.shape({
      projectId: PropTypes.string,
    }),
  }).isRequired,
  fetchProject: PropTypes.func.isRequired,
  projectName: PropTypes.string,
  baseTitle: PropTypes.string,
}

export default connect(
  mapStateToProps,
  { fetchProject },
)(BreadcrumbsContainer)
