import * as types from './constants'
import { getNextPageOffset } from './selectors'

export { requestFailure, requestSuccess } from '../actions'

const fetchTasksRequest = (projectId, offset) => ({
  type: types.FETCH_LIST_REQUEST,
  projectId,
  offset,
})

export const createTask = (projectId, formData, resolve, reject) => ({
  type: types.CREATE_REQUEST,
  projectId,
  formData,
  resolve,
  reject,
})

export const fetchTasks = (projectId, nextPage = false) => (dispatch, getState) => {
  if (nextPage) {
    const offset = getNextPageOffset(getState())
    if (offset) {
      return dispatch(fetchTasksRequest(projectId, offset))
    }
    return null
  }

  return dispatch(fetchTasksRequest(projectId, 0))
}

export const fetchTask = (projectId, taskId) => ({
  type: types.FETCH_TASK_REQUEST,
  projectId,
  taskId,
})

export const updateTask = (projectId, taskId, payload) => ({
  type: types.UPDATE_TASK_REQUEST,
  projectId,
  taskId,
  payload,
})

export const enableSync = (projectId, taskId) => ({
  type: types.ENABLE_SYNC_REQUEST,
  projectId,
  taskId,
})
