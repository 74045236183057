export const RELEVANCE_LOW = 'low'
export const RELEVANCE_NORMAL = 'normal'
export const RELEVANCE_HIGH = 'high'

export const list = () => [RELEVANCE_HIGH, RELEVANCE_NORMAL, RELEVANCE_LOW]

export const translate = level => {
  switch (level) {
    case RELEVANCE_LOW:
      return 'низкая'
    case RELEVANCE_NORMAL:
      return 'средняя'
    case RELEVANCE_HIGH:
      return 'высокая'
    default:
      return level
  }
}
