import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { AUTH as redirectTo } from '../../constants/routes'
import { fetchProfile, logout, isFetching, getProfile } from '../../stores/user'

const mapStateToProps = state => ({
  profile: getProfile(state),
  isFetching: isFetching(state),
})

class LoginButton extends Component {
  componentWillMount() {
    this.props.fetchProfile()
  }

  render() {
    const { profile, isFetching, logout } = this.props
    if (profile) {
      return (
        <Button
          as={Link}
          to={redirectTo}
          onClick={logout}
          size="tiny"
          loading={isFetching}
          icon
          labelPosition="right"
        >
          {profile.email || 'Выйти'}
          <Icon name="sign out" />
        </Button>
      )
    }

    return (
      <Button as={Link} to={redirectTo} size="tiny" icon labelPosition="right">
        Войти
        <Icon name="sign in" />
      </Button>
    )
  }
}

LoginButton.defaultProps = {
  profile: null,
}

LoginButton.propTypes = {
  profile: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }),
  isFetching: PropTypes.bool.isRequired,
  fetchProfile: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  { fetchProfile, logout },
)(LoginButton)
