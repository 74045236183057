import { createSelector } from 'reselect'

const getTasksById = state => state.tasks.byId
const getTasksIds = state => state.tasks.ids

export const isFetching = state => state.tasks.isFetching
export const getNextPageOffset = state => state.tasks.nextPageOffset
export const getTotal = state => state.tasks.total

export const getTasks = createSelector(
  getTasksById,
  getTasksIds,
  (entities, ids) => ids.map(id => entities[id]),
)

export const getTask = createSelector(
  getTasksById,
  (state, id) => ({ state, id }),
  (entities, { id }) => entities[id],
)
