import React from 'react'
import PropTypes from 'prop-types'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import { Button, Icon, Message, Rail } from 'semantic-ui-react'
import styled from 'styled-components/macro'
import ContentContainer from '../../components/ContentContainer'
import Spinner from '../../components/Spinner'
import TasksList from './TasksList'
import * as routes from '../../../constants/routes'

const RigthMenuWrapper = styled.div`
  margin-top: 1rem;
`

const TasksListPage = ({ projectId, tasks, isFetching, canCreateTask }) => {
  const formPagePath = generatePath(routes.TASK_NEW, { projectId })
  return (
    <ContentContainer headerContent="Список задач">
      <Rail position="right">
        <Button
          as={Link}
          to={formPagePath}
          disabled={!canCreateTask}
          positive
          icon="add"
          content="Создать задачу"
        />
        <RigthMenuWrapper>
          <Link to={generatePath(routes.LOG_EVENTS, { projectId })}>
            <Icon name="history" />
            История изменений
          </Link>
        </RigthMenuWrapper>
      </Rail>
      <TasksList projectId={projectId} tasks={tasks} />
      {!tasks.length && !isFetching && (
        <Message info>
          <p>Пока нет ни одной задачи.</p>
          {canCreateTask && (
            <p>
              Вы можете <Link to={formPagePath}>создать</Link> новую задачу прямо сейчас.
            </p>
          )}
        </Message>
      )}
      {isFetching && <Spinner inline />}
    </ContentContainer>
  )
}

TasksListPage.propTypes = {
  projectId: PropTypes.string.isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  isFetching: PropTypes.bool.isRequired,
  canCreateTask: PropTypes.bool.isRequired,
}

export default TasksListPage
