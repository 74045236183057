import { createSelector } from 'reselect'

const branchKey = taskId => taskId || 'project'

const getCampaignsById = (state, taskId) => state.campaigns.byId[branchKey(taskId)] || {}
const getCampaignsIds = (state, taskId) => state.campaigns.ids[branchKey(taskId)] || []

export const isFetching = (state, taskId) => state.campaigns.isFetching[branchKey(taskId)] || false

export const getCampaigns = createSelector(
  getCampaignsById,
  getCampaignsIds,
  (entities, ids) => ids.map(id => entities[id]),
)
