import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import hoistStatics from 'hoist-non-react-statics'
import { fetchTask, getTask } from '../../stores/tasks'

const mapStateToProps = (
  state,
  {
    match: {
      params: { taskId },
    },
  },
) => ({
  task: getTask(state, taskId),
})

const withTask = WrappedComponent => {
  class Wrapper extends Component {
    componentWillMount() {
      const {
        match: {
          params: { projectId, taskId },
        },
        fetchTask,
      } = this.props
      fetchTask(projectId, taskId)
    }

    render() {
      const { task } = this.props

      if (task && task.status) {
        return <WrappedComponent {...this.props} />
      }

      return null
    }
  }

  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component'

  Wrapper.displayName = `withTask(${displayName})`
  Wrapper.WrappedComponent = WrappedComponent

  Wrapper.defaultProps = {
    task: null,
  }

  Wrapper.propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        projectId: PropTypes.string,
        taskId: PropTypes.string,
      }).isRequired,
    }).isRequired,
    fetchTask: PropTypes.func.isRequired,
    task: PropTypes.shape({
      id: PropTypes.number,
      status: PropTypes.string,
    }),
  }

  return connect(
    mapStateToProps,
    {
      fetchTask,
    },
  )(hoistStatics(Wrapper, WrappedComponent))
}

export default withTask
