import { createSelector } from 'reselect'

const getEntriesById = state => state.log.entryById
const getUsersById = state => state.log.userById
const getIds = state => state.log.ids

export const isFetching = state => state.log.isFetching
export const getTotalPages = state => state.log.totalPages

export const getLogItems = createSelector(
  getIds,
  getEntriesById,
  getUsersById,
  (ids, logEntries, users) =>
    ids.map(id => {
      const entry = logEntries[id]
      return {
        ...entry,
        user: users[entry.user],
      }
    }),
)
