import { all } from 'redux-saga/effects'
import { userSaga } from './user'
import { tasksSaga } from './tasks'
import { reportSaga } from './report'
import { logEventsSaga } from './log'
import { campaignsSaga } from './campaigns'

export default function* rootSaga() {
  yield all([tasksSaga(), reportSaga(), logEventsSaga(), userSaga(), campaignsSaga()])
}
