import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { pick } from 'lodash'
import { FETCH_DETAILS_FAILURE as FETCH_PROJECT_FAILURE } from '@airmedia/projects-embed'
import ReportPage from './components/ReportPage'
import makeRequestErrorWrapper from '../decorators/makeRequestErrorWrapper'
import withTask from '../decorators/withTask'
import Loading from '../decorators/Loading'
import { allowReport } from '../../constants/taskStatuses'
import { enableSync, FETCH_TASK_FAILURE, updateTask } from '../../stores/tasks'
import { loadReportPage, resetReportData } from '../../stores/report'
import equalFilter from '../../utils/equalFilter'

const FILTER_PARAMS = ['status', 'campaign', 'group', 'keyword', 'relevance']

const getFilter = searchParams =>
  FILTER_PARAMS.reduce((filter, key) => {
    /* eslint-disable no-param-reassign */
    if (searchParams.has(key)) {
      filter[key] = searchParams
        .get(key)
        .split(',')
        .filter(x => x)
    }
    /* eslint-enable no-param-reassign */
    return filter
  }, {})

const getPage = searchParams => {
  if (searchParams.has('page')) {
    return Number(searchParams.get('page'))
  }
  return 1
}

const mapStateToProps = (
  state,
  {
    match: {
      params: { projectId, taskId },
    },
  },
) => ({
  projectId,
  taskId,
})

class ReportPageContainer extends Component {
  componentDidMount() {
    this.loadReportData()
  }

  componentDidUpdate(prevProps) {
    const prevTask = prevProps.task || {}
    const task = this.props.task || {}

    if (
      prevTask.minRelevanceLevel &&
      task.minRelevanceLevel &&
      prevTask.minRelevanceLevel !== task.minRelevanceLevel
    ) {
      const searchParams = this.createSearchParams()
      searchParams.set('page', '1')

      FILTER_PARAMS.forEach(key => {
        if (searchParams.has(key)) {
          searchParams.delete(key)
        }
      })

      if (this.updateQuery(searchParams)) {
        return
      }
    }

    const prevQueryParams = new URLSearchParams(prevProps.location.search)
    const queryParams = this.createSearchParams()

    if (
      prevProps.projectId !== this.props.projectId ||
      prevProps.taskId !== this.props.taskId ||
      prevTask.status !== task.status ||
      getPage(prevQueryParams) !== getPage(queryParams) ||
      !equalFilter(getFilter(prevQueryParams), getFilter(queryParams))
    ) {
      this.loadReportData()
    }
  }

  componentWillUnmount() {
    this.props.resetReportData()
  }

  setFilter = (filter = {}) => {
    const params = this.createSearchParams()
    const currentFilter = getFilter(params)
    FILTER_PARAMS.forEach(key => {
      if (key in filter) {
        const value = filter[key]
        if (!equalFilter(pick(filter, key), pick(currentFilter, key))) {
          params.set('page', '1')
        }
        if (value) {
          params.set(key, Array.isArray(value) ? value.join(',') : value.toString())
        } else {
          params.delete(key)
        }
      }
    })
    this.updateQuery(params)
  }

  setPage = page => {
    const params = this.createSearchParams(true)
    params.set('page', page)
    this.updateQuery(params)
  }

  setStatus = statuses => {
    this.setFilter({
      status: statuses,
    })
  }

  setRelevance = relevances => {
    this.setFilter({
      relevance: relevances,
    })
  }

  syncTask = () => {
    const { enableSync, projectId, taskId } = this.props
    enableSync(projectId, taskId)
  }

  setMinRelevanceLevel = relevance => {
    const { projectId, taskId, updateTask } = this.props
    updateTask(projectId, taskId, {
      minRelevanceLevel: relevance,
    })
  }

  createSearchParams() {
    return new URLSearchParams(this.props.location.search)
  }

  loadReportData() {
    const { loadReportPage, projectId, taskId, task } = this.props
    if (task && allowReport(task.status)) {
      const searchParams = this.createSearchParams()
      loadReportPage(projectId, taskId, getPage(searchParams), getFilter(searchParams))
    }
  }

  updateQuery(searchParams) {
    const query = searchParams.toString()
    const { history, location } = this.props
    if (location.search !== query) {
      history.push(`${location.pathname}?${query}`)
      return true
    }

    return false
  }

  render() {
    const { task } = this.props
    const searchParams = this.createSearchParams()

    return (
      <ReportPage
        task={task}
        syncTask={this.syncTask}
        setFilter={this.setFilter}
        setPage={this.setPage}
        setStatus={this.setStatus}
        setRelevance={this.setRelevance}
        setMinRelevanceLevel={this.setMinRelevanceLevel}
        currentPage={getPage(searchParams)}
        filter={getFilter(searchParams)}
      />
    )
  }
}

ReportPageContainer.propTypes = {
  projectId: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  enableSync: PropTypes.func.isRequired,
  loadReportPage: PropTypes.func.isRequired,
  resetReportData: PropTypes.func.isRequired,
  updateTask: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  {
    enableSync,
    loadReportPage,
    resetReportData,
    updateTask,
  },
)(
  compose(
    makeRequestErrorWrapper([FETCH_PROJECT_FAILURE, FETCH_TASK_FAILURE]),
    withTask,
    Loading,
  )(ReportPageContainer),
)
