import * as types from './constants'

export { requestFailure, requestSuccess } from '../actions'

export const fetchCampaigns = (projectId, taskId = null) => ({
  type: types.FETCH_LIST_REQUEST,
  projectId,
  taskId,
})

export const resetCampaigns = (taskId = null) => ({
  type: types.RESET,
  taskId,
})
