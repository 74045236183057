import omit from 'lodash/omit'
import { RESET_ERROR } from './constants'

export * from './actions'
export * from './constants'
export * from './selectors'

const failureTypeRe = /_FAILURE$/
const successTypeRe = /_SUCCESS$/

export default (state = {}, action) => {
  if (failureTypeRe.test(action.type)) {
    return {
      ...state,
      [action.type]: action.error,
    }
  }

  if (successTypeRe.test(action.type)) {
    const failureType = action.type.replace(successTypeRe, '_FAILURE')
    return omit(state, failureType)
  }

  if (action.type === RESET_ERROR) {
    return omit(state, action.failureTypes)
  }

  return state
}
