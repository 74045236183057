import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withInfiniteScroll } from 'pannier'
import {
  FETCH_DETAILS_FAILURE as FETCH_PROJECT_FAILURE,
  fetchProject,
  getProject,
} from '@airmedia/projects-embed'
import RequestError from '../components/RequestError'
import TasksListPage from './components/TasksListPage'
import { FETCH_LIST_FAILURE, fetchTasks, getTasks, getTotal, isFetching } from '../../stores/tasks'
import { yandexNotConnected } from '../../utils/notConnected'

const TasksListPageWithInfiniteScroll = withInfiniteScroll(TasksListPage)

const mapStateToProps = (
  state,
  {
    match: {
      params: { projectId },
    },
  },
) => ({
  projectId,
  project: getProject(state, projectId),
  tasks: getTasks(state),
  total: getTotal(state),
  isFetching: isFetching(state),
})

class TasksListPageContainer extends Component {
  componentDidMount() {
    const { fetchTasks, projectId, fetchProject, project } = this.props
    fetchTasks(projectId)

    if (!project || !project.yandexDirectStatus) {
      fetchProject(projectId)
    }
  }

  fetchData = () => {
    const { fetchTasks, projectId } = this.props
    fetchTasks(projectId, true)
  }

  render() {
    const { projectId, tasks, isFetching, total, project } = this.props
    const trackScroll = !isFetching && tasks.length < total

    return (
      <RequestError actionTypes={[FETCH_LIST_FAILURE, FETCH_PROJECT_FAILURE]}>
        <TasksListPageWithInfiniteScroll
          fetchData={this.fetchData}
          trackScroll={trackScroll}
          projectId={projectId}
          tasks={tasks}
          isFetching={isFetching}
          canCreateTask={!!(project && !yandexNotConnected(project))}
        />
      </RequestError>
    )
  }
}

TasksListPageContainer.defaultProps = {
  project: null,
}

TasksListPageContainer.propTypes = {
  projectId: PropTypes.string.isRequired,
  project: PropTypes.shape({
    id: PropTypes.string,
    yandexDirectStatus: PropTypes.string,
  }),
  fetchProject: PropTypes.func.isRequired,
  fetchTasks: PropTypes.func.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.any).isRequired,
  total: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
}

export default connect(
  mapStateToProps,
  { fetchTasks, fetchProject },
)(TasksListPageContainer)
