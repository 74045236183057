import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'semantic-ui-react'
import TasksListItem from './TasksListItem'

const TasksList = ({ tasks, projectId }) => (
  <List divided selection>
    {tasks && tasks.map(task => <TasksListItem key={task.id} task={task} projectId={projectId} />)}
  </List>
)

TasksList.defaultProps = {
  tasks: null,
}

TasksList.propTypes = {
  projectId: PropTypes.string.isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ),
}

export default TasksList
