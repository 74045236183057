import React, { Fragment, Suspense, lazy } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { AuthRoute, withAuth } from 'auth-component'
import GlobalStyles from '../styles'
import * as routes from '../constants/routes'
import IndexPage from './IndexPage'
import Page404 from './Page404'
import TasksListPage from './TasksListPage'
import NewTaskPage from './NewTaskPage'
import ReportPage from './ReportPage'
import TaskCampaignsPage from './TaskCampaignsPage'
import ErrorHandler from './components/ErrorHandler'
import Header from './components/Header'
import Spinner from './components/Spinner'
import YandexNotAvailableMessage from './components/YandexNotAvailableMessage'

const DefaultLayout = ({ component: Component, ...props }) => (
  <Fragment>
    <Header {...props} />
    <YandexNotAvailableMessage {...props} />
    <ErrorHandler>
      <Suspense fallback={<Spinner />}>
        <Component {...props} />
      </Suspense>
    </ErrorHandler>
  </Fragment>
)

DefaultLayout.propTypes = {
  component: PropTypes.func.isRequired,
}

const RouteWithLayout = ({ layout: Layout, component, ...props }) => (
  <Route {...props} render={props => <Layout component={component} {...props} />} />
)

RouteWithLayout.propTypes = {
  layout: PropTypes.func.isRequired,
  component: PropTypes.func.isRequired,
}

const IndexPageWithAuth = withAuth(IndexPage)
const TasksListPageWithAuth = withAuth(TasksListPage)
const NewTaskPageWithAuth = withAuth(NewTaskPage)
const ReportPageWithAuth = withAuth(ReportPage)
const LogPageWithAuth = withAuth(lazy(() => import('./LogPage')))
const TaskCampaignsPageWithAuth = withAuth(TaskCampaignsPage)

const App = () => (
  <ErrorHandler>
    <GlobalStyles />
    <Switch>
      <AuthRoute path={routes.AUTH} />
      <RouteWithLayout
        exact
        path={routes.INDEX}
        layout={DefaultLayout}
        component={IndexPageWithAuth}
      />
      <RouteWithLayout
        exact
        path={routes.TASKS_LIST}
        layout={DefaultLayout}
        component={TasksListPageWithAuth}
      />
      <RouteWithLayout
        exact
        path={routes.TASK_NEW}
        layout={DefaultLayout}
        component={NewTaskPageWithAuth}
      />
      <RouteWithLayout
        exact
        path={routes.TASK_DETAILS}
        layout={DefaultLayout}
        component={ReportPageWithAuth}
      />
      <RouteWithLayout
        exact
        path={routes.TASK_CAMPAIGNS}
        layout={DefaultLayout}
        component={TaskCampaignsPageWithAuth}
      />
      <RouteWithLayout
        exact
        path={routes.LOG_EVENTS}
        layout={DefaultLayout}
        component={LogPageWithAuth}
      />
      <Route component={Page404} />
    </Switch>
  </ErrorHandler>
)

export default App
