import * as types from './constants'

export { requestFailure, requestSuccess } from '../actions'

export const loadReportPage = (projectId, taskId, page = 1, filters = {}) => ({
  type: types.FETCH_PAGE_REQUEST,
  projectId,
  taskId,
  page,
  filters,
})

export const setStatsByStatus = stats => ({
  type: types.SET_STATS_BY_STATUS,
  stats,
})

export const setStatsByRelevance = stats => ({
  type: types.SET_STATS_BY_RELEVANCE,
  stats,
})

export const resetReportData = () => ({
  type: types.RESET,
})
