import { call, put, takeLatest } from 'redux-saga/effects'
import { requestFailure, requestSuccess } from './actions'
import * as types from './constants'
import * as api from '../../api'

const PAGE_SIZE = 50

export function* fetchLogEventsPage({ projectId, page, filters }) {
  try {
    const offset = (page - 1) * PAGE_SIZE
    const payload = yield call(api.fetchLog, projectId, filters, PAGE_SIZE, offset)
    yield put(
      requestSuccess(types.FETCH_PAGE_SUCCESS, payload, {
        projectId,
        page,
        totalPages: Math.ceil(payload.meta.total / PAGE_SIZE),
        filters,
      }),
    )
  } catch (error) {
    yield put(
      requestFailure(types.FETCH_PAGE_FAILURE, error, {
        projectId,
        page,
        filters,
      }),
    )
  }
}

export function* logEventsSaga() {
  yield takeLatest(types.FETCH_PAGE_REQUEST, fetchLogEventsPage)
}
