import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import uniqBy from 'lodash/uniqBy'
import { TextAreaReduxForm } from 'pannier/form'

const formatFormData = value => value && value.join('\n')
const parseFormData = value => (value ? value.replace(/\r\n|\r/g, '\n').split('\n') : [])
const makeHandleBlur = onBlur => ({ target: { value } }) => {
  const uniqValues = formatFormData(
    uniqBy(
      parseFormData(value)
        .map(x => x.trim())
        .filter(x => x),
      x => x.toLowerCase(),
    ),
  )
  if (uniqValues !== value) {
    onBlur(uniqValues)
  }
}

const renderTextArea = ({ input, ...rest }) => (
  <TextAreaReduxForm
    input={{
      ...input,
      onBlur: makeHandleBlur(input.onBlur),
    }}
    {...rest}
  />
)

renderTextArea.propTypes = {
  input: PropTypes.shape({
    onBlur: PropTypes.func,
  }).isRequired,
}

const ExcludeTemplatesField = ({ name, label, readOnly }) => (
  <Field
    name={name}
    label={label}
    readOnly={readOnly}
    component={renderTextArea}
    format={formatFormData}
    parse={parseFormData}
    rows={10}
  />
)

ExcludeTemplatesField.defaultProps = {
  readOnly: false,
}

ExcludeTemplatesField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
}

export default ExcludeTemplatesField
