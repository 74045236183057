import { call, put, takeLatest } from 'redux-saga/effects'
import { requestFailure, requestSuccess, setStatsByRelevance, setStatsByStatus } from './actions'
import * as types from './constants'
import * as api from '../../api'

const PAGE_SIZE = 20

export function* fetchReportPage({ projectId, taskId, page, filters }) {
  try {
    const offset = (page - 1) * PAGE_SIZE
    const payload = yield call(api.fetchReport, projectId, taskId, filters, PAGE_SIZE, offset)
    yield put(
      requestSuccess(types.FETCH_PAGE_SUCCESS, payload, {
        projectId,
        taskId,
        page,
        totalPages: Math.ceil(payload.meta.total / PAGE_SIZE),
        filters,
      }),
    )
    yield put(setStatsByStatus(payload.meta.statuses))
    yield put(setStatsByRelevance(payload.meta.relevances))
  } catch (error) {
    yield put(
      requestFailure(types.FETCH_PAGE_FAILURE, error, {
        projectId,
        taskId,
        page,
        filters,
      }),
    )
  }
}

export function* reportSaga() {
  yield takeLatest(types.FETCH_PAGE_REQUEST, fetchReportPage)
}
