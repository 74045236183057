import React from 'react'
import PropTypes from 'prop-types'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Dimmer, List } from 'semantic-ui-react'
import { LongDate } from '@airmedia/react-time'
import { makeTrans } from 'pannier'
import TaskStatus from '../../components/TaskStatus'
import * as routes from '../../../constants/routes'
import { TEXT_COLOR_LIGHT, TEXT_COLOR_MUTED } from '../../../styles/variables'

const trans = makeTrans({
  keywords_plural_0: ({ count }) => `${count} ключ`,
  keywords_plural_1: ({ count }) => `${count} ключа`,
  keywords_plural_2: ({ count }) => `${count} ключей`,
})

const Statistics = ({ total, totalKeywords, fixed, fixedKeywords }) => {
  const rest = total - fixed
  const restKeywords = totalKeywords - fixedKeywords

  if (restKeywords > 0) {
    return (
      <List.Description>
        Всего групп с &quot;мало показов&quot; <strong>{total}</strong> (
        {trans('keywords', { count: totalKeywords })}
        ), из них исправлено <strong>{fixed}</strong> (
        {trans('keywords', { count: fixedKeywords })}
        ), не удалось <strong>{rest}</strong> (
        {trans('keywords', { count: restKeywords })}
        ).
      </List.Description>
    )
  }

  return (
    <List.Description>
      Всего групп с &quot;мало показов&quot; <strong>{total}</strong> (
      {trans('keywords', { count: totalKeywords })}
      ), из них исправлено <strong>{fixed}</strong> (
      {trans('keywords', { count: fixedKeywords })}
      ).
    </List.Description>
  )
}

Statistics.propTypes = {
  total: PropTypes.number.isRequired,
  fixed: PropTypes.number.isRequired,
  totalKeywords: PropTypes.number.isRequired,
  fixedKeywords: PropTypes.number.isRequired,
}

const TasksListItem = ({
  className,
  projectId,
  task: { id, name, status, createdAt, updatedAt, rarelyServedGroups },
}) => (
  <Dimmer.Dimmable as={List.Item} key={id} className={className}>
    <List.Content
      key={id}
      as={Link}
      to={generatePath(routes.TASK_DETAILS, { projectId, taskId: id })}
    >
      <List.Header>
        <span className="id">№{id}</span>: {name}
      </List.Header>
      <List.Description>
        <TaskStatus status={status} updatedAt={updatedAt} mode="inline" />
        {rarelyServedGroups && <Statistics {...rarelyServedGroups} />}
      </List.Description>
      <List.Description className="extra">
        <LongDate date={createdAt} />
      </List.Description>
    </List.Content>
  </Dimmer.Dimmable>
)

TasksListItem.propTypes = {
  className: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    rarelyServedGroups: PropTypes.shape({
      total: PropTypes.number.isRequired,
      fixed: PropTypes.number.isRequired,
      totalKeywords: PropTypes.number.isRequired,
      fixedKeywords: PropTypes.number.isRequired,
    }),
  }).isRequired,
}

const StyledTasksListItem = styled(TasksListItem)`
  .header > .id {
    color: ${TEXT_COLOR_MUTED};
  }

  .description {
    margin-top: 0.5em;
  }

  .description.extra {
    color: ${TEXT_COLOR_LIGHT} !important;
    text-align: right;
  }
`

StyledTasksListItem.WrappedComponent = TasksListItem

export default StyledTasksListItem
