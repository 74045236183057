import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import { omit } from 'lodash'
import * as types from './constants'
import { setFalse, setTrue } from '../actionHandlers'

export * from './actions'
export * from './constants'
export * from './selectors'
export { campaignsSaga } from './sagas'

const getBranchKey = action => action.taskId || 'project'

const makeBranchHandler = (defaultValue, handler) => (state, action) => {
  const key = getBranchKey(action)
  return {
    ...state,
    [key]: handler(state[key] || defaultValue, action),
  }
}

const removeBranch = (state, action) => {
  const key = getBranchKey(action)
  if (key in state) {
    return omit(state, key)
  }
  return state
}

export default combineReducers({
  byId: handleActions(
    {
      [types.FETCH_LIST_SUCCESS]: makeBranchHandler(
        {},
        (state, action) => action.entities.campaigns,
      ),
      [types.RESET]: removeBranch,
    },
    {},
  ),
  ids: handleActions(
    {
      [types.FETCH_LIST_SUCCESS]: makeBranchHandler([], (state, action) => action.result),
      [types.RESET]: removeBranch,
    },
    {},
  ),
  isFetching: handleActions(
    {
      [types.FETCH_LIST_REQUEST]: makeBranchHandler(false, setTrue),
      [types.FETCH_LIST_SUCCESS]: makeBranchHandler(false, setFalse),
      [types.FETCH_LIST_FAILURE]: makeBranchHandler(false, setFalse),
      [types.RESET]: removeBranch,
    },
    {},
  ),
})
