const prefix = 'tasks/'

export const CREATE_REQUEST = `${prefix}CREATE_REQUEST`
export const CREATE_SUCCESS = `${prefix}CREATE_SUCCESS`
export const CREATE_FAILURE = `${prefix}CREATE_FAILURE`

export const FETCH_LIST_REQUEST = `${prefix}FETCH_LIST_REQUEST`
export const FETCH_LIST_SUCCESS = `${prefix}FETCH_LIST_SUCCESS`
export const FETCH_LIST_FAILURE = `${prefix}FETCH_LIST_FAILURE`

export const FETCH_TASK_REQUEST = `${prefix}FETCH_TASK_REQUEST`
export const FETCH_TASK_SUCCESS = `${prefix}FETCH_TASK_SUCCESS`
export const FETCH_TASK_FAILURE = `${prefix}FETCH_TASK_FAILURE`

export const ENABLE_SYNC_REQUEST = `${prefix}ENABLE_SYNC_REQUEST`
export const ENABLE_SYNC_SUCCESS = `${prefix}ENABLE_SYNC_SUCCESS`
export const ENABLE_SYNC_FAILURE = `${prefix}ENABLE_SYNC_FAILURE`

export const UPDATE_TASK_REQUEST = `${prefix}UPDATE_TASK_REQUEST`
export const UPDATE_TASK_SUCCESS = `${prefix}UPDATE_TASK_SUCCESS`
export const UPDATE_TASK_FAILURE = `${prefix}UPDATE_TASK_FAILURE`
