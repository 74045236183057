import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Grid, Icon, Message } from 'semantic-ui-react'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { makeTrans } from 'pannier'
import { SIZE_TINY, TEXT_COLOR_LIGHT } from '../../../styles/variables'
import * as routes from '../../../constants/routes'
import { allowReport, canSync, inProgress } from '../../../constants/taskStatuses'
import ContentContainer from '../../components/ContentContainer'
import Filter from './Filter'
import ReportResults from './ReportResults'
import TaskStatus from '../../components/TaskStatus'
import SyncButton from './SyncButton'
import SyncStatusFilter from './SyncStatusFilter'
import RelevanceFilter from './RelevanceFilter'
import MinRelevanceLevelSelect from './MinRelevanceLevelSelect'

const trans = makeTrans({
  sync_keywords_plural_0: ({ count }) => `${count} ключа`,
  sync_keywords_plural_1: ({ count }) => `${count} ключей`,
  sync_keywords_plural_2: ({ count }) => `${count} ключей`,
})

const LineWrapper = styled.div`
  margin-bottom: 1em;
`

const StyledMeta = styled.p`
  font-size: ${SIZE_TINY}rem;
  color: ${TEXT_COLOR_LIGHT};
`

const ReportPage = ({
  task,
  filter,
  syncTask,
  setFilter,
  setStatus,
  setRelevance,
  setPage,
  currentPage,
  setMinRelevanceLevel,
}) => (
  <ContentContainer mode="wide" headerContent={task.name}>
    <Grid>
      <Grid.Row>
        <Grid.Column width={10}>
          {allowReport(task.status) && <Filter filter={filter} setFilter={setFilter} />}
        </Grid.Column>
        <Grid.Column width={6} textAlign="right" verticalAlign="bottom">
          {allowReport(task.status) && (
            <LineWrapper>
              <Link
                to={generatePath(routes.TASK_CAMPAIGNS, {
                  projectId: task.projectId,
                  taskId: task.id,
                })}
              >
                Список кампаний
              </Link>
            </LineWrapper>
          )}
          <TaskStatus {...task} />
          {canSync(task.status) && task.rarelyServedGroups && (
            <StyledMeta>
              <Icon name="info" size="small" />
              Будут выгружены изменения для{' '}
              {trans('sync_keywords', {
                count:
                  task.rarelyServedGroups.syncKeywords || task.rarelyServedGroups.fixedKeywords,
              })}
            </StyledMeta>
          )}
          {task.minRelevanceLevel && (
            <LineWrapper>
              <MinRelevanceLevelSelect
                disabled={!canSync(task.status)}
                value={task.minRelevanceLevel}
                onChange={setMinRelevanceLevel}
                updating={task.isUpdating}
              />
            </LineWrapper>
          )}
          <SyncButton
            onClick={syncTask}
            isSyncing={task.isSyncing || false}
            disabled={!canSync(task.status)}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
    {allowReport(task.status) && (
      <Fragment>
        <SyncStatusFilter filter={filter.status || []} setFilter={setStatus} />
        <RelevanceFilter filter={filter.relevance || []} setFilter={setRelevance} />
        <ReportResults
          projectId={task.projectId}
          taskId={task.id}
          setPage={setPage}
          currentPage={currentPage}
          filters={filter}
        />
      </Fragment>
    )}
    {!allowReport(task.status) && (
      <Message info icon>
        <Icon name={inProgress(task.status) ? 'wait' : 'info circle'} />
        <Message.Content>
          {inProgress(task.status) && <p>Пожалуйста, подождите...</p>}
          <p>Данные будут доступны после завершения перегруппировки.</p>
        </Message.Content>
      </Message>
    )}
  </ContentContainer>
)

ReportPage.defaultProps = {
  filter: {},
}

ReportPage.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
    projectId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    minRelevanceLevel: PropTypes.string,
    isUpdating: PropTypes.bool,
    isSyncing: PropTypes.bool,
    rarelyServedGroups: PropTypes.shape({
      syncKeywords: PropTypes.number,
      fixedKeywords: PropTypes.number,
    }),
  }).isRequired,
  currentPage: PropTypes.number.isRequired,
  syncTask: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  setRelevance: PropTypes.func.isRequired,
  setMinRelevanceLevel: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    campaign: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    group: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    keyword: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    status: PropTypes.arrayOf(PropTypes.string),
    relevance: PropTypes.arrayOf(PropTypes.string),
  }),
}

export default ReportPage
