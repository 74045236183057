import React from 'react'
import PropTypes from 'prop-types'
import hoistStatics from 'hoist-non-react-statics'
import RequestError from '../components/RequestError'

export default actionTypes => WrappedComponent => {
  const Wrapper = props => (
    <RequestError location={props.location} actionTypes={actionTypes}>
      <WrappedComponent {...props} />
    </RequestError>
  )

  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component'

  Wrapper.displayName = `requestError(${displayName})`
  Wrapper.WrappedComponent = WrappedComponent

  Wrapper.propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }).isRequired,
  }

  return hoistStatics(Wrapper, WrappedComponent)
}
