import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Button, Form, Segment } from 'semantic-ui-react'
import equalFilter from '../../../utils/equalFilter'
import ResettableInput from '../../components/ResettableInput'

export const formatFilterValue = value => {
  if (value && Array.isArray(value)) {
    return value.join(' ')
  }
  return value || ''
}

export const parseFilterValue = value => {
  if (!value) {
    return null
  }
  const trimValue = value.trim()
  if (!trimValue) {
    return null
  }
  return value
    .replace(',', ' ')
    .split(' ')
    .filter(x => !!x)
}

class Filter extends Component {
  state = {
    campaign: '',
    group: '',
    keyword: '',
    prevFilter: null, // eslint-disable-line react/no-unused-state
  }

  static getDerivedStateFromProps(props, state) {
    const { filter } = props
    if (!filter || equalFilter(filter, state.prevFilter)) {
      return null
    }
    return {
      campaign: formatFilterValue(filter.campaign),
      group: formatFilterValue(filter.group),
      keyword: formatFilterValue(filter.keyword),
      prevFilter: props.filter,
    }
  }

  handleChange = (e, { name, value }) => {
    this.setState({
      [name]: value,
    })
  }

  handleReset = ({ name }) => {
    this.setState({
      [name]: '',
    })
  }

  handleSubmit = () => {
    const { campaign, group, keyword } = this.state
    this.props.setFilter({
      campaign: parseFilterValue(campaign),
      group: parseFilterValue(group),
      keyword: parseFilterValue(keyword),
    })
  }

  render() {
    const { className } = this.props
    const { campaign, group, keyword } = this.state

    return (
      <Segment className={className}>
        <Form>
          <ResettableInput
            className="search-param"
            inline
            width={16}
            name="campaign"
            value={campaign}
            label="№ кампаний:"
            placeholder="Поиск по РК..."
            onChange={this.handleChange}
            onReset={this.handleReset}
          />
          <ResettableInput
            className="search-param"
            inline
            width={16}
            name="group"
            value={group}
            label="№ групп:"
            placeholder="Поиск по группам объявлений..."
            onChange={this.handleChange}
            onReset={this.handleReset}
          />
          <ResettableInput
            className="search-param"
            inline
            width={16}
            name="keyword"
            value={keyword}
            label="№ ключевых фраз:"
            placeholder="Поиск по ключевым фразам..."
            onChange={this.handleChange}
            onReset={this.handleReset}
          />
          <Button content="Найти" onClick={this.handleSubmit} />
        </Form>
      </Segment>
    )
  }
}

Filter.defaultProps = {
  className: null,
  filter: null,
}

Filter.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.shape({
    campaign: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    group: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    keyword: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  }),
  setFilter: PropTypes.func.isRequired,
}

const FilterStyled = styled(Filter)`
  & .search-param {
    display: flex;
    align-items: center;

    label {
      white-space: nowrap;
      width: 22% !important;
    }
  }
`

FilterStyled.WrappedComponent = Filter

export default FilterStyled
