import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'semantic-ui-react'
import Tooltip from '../../components/Tooltip'
import { list } from '../../../constants/relevances'

const relevanceLevelOptions = [
  {
    key: 'low',
    text: 'Все',
    value: 'low',
  },
  {
    key: 'normal',
    text: 'Средняя релевантность и выше',
    value: 'normal',
  },
  {
    key: 'high',
    text: 'Только высокая релевантность',
    value: 'high',
  },
]

const MinRelevanceLevelSelect = ({ value, updating, disabled, onChange }) => (
  <React.Fragment>
    <Dropdown
      selection
      options={relevanceLevelOptions}
      loading={updating}
      disabled={disabled}
      value={value}
      onChange={(e, { value }) => onChange(value)}
      data-for="min-relevance-level-select"
      data-tip="Какие изменения выгружать в Яндекс.Директ?"
    />
    <Tooltip id="min-relevance-level-select" place="left" />
  </React.Fragment>
)

MinRelevanceLevelSelect.defaultProps = {
  updating: false,
}

MinRelevanceLevelSelect.propTypes = {
  disabled: PropTypes.bool.isRequired,
  value: PropTypes.oneOf(list()).isRequired,
  onChange: PropTypes.func.isRequired,
  updating: PropTypes.bool,
}

export default MinRelevanceLevelSelect
