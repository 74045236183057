import React from 'react'
import { render } from 'react-dom'
import { initializeIcons } from '@uifabric/icons'
import { loadTheme } from '@uifabric/styling'
import Root from './views/Root'
import configureStore from './stores/configureStore'
import { init } from './analytics'

const store = configureStore()

init()
initializeIcons()
// Workaround to prevent error on prod: Cannot read property 'disableGlobalClassNames' of undefined
// See issue: https://github.com/OfficeDev/office-ui-fabric-react/issues/8242
loadTheme({})
render(<Root store={store} />, document.getElementById('root'))
