import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Popup, Icon } from 'semantic-ui-react'
import styled from 'styled-components/macro'

const withoutNegativeWords = keyword => {
  const pos = keyword.indexOf(' -')
  if (pos === -1) {
    return keyword
  }
  return keyword.substr(0, pos)
}

const MoreIcon = styled(Icon)`
  cursor: pointer;
`

const KeywordText = ({ keyword }) => {
  const shortKeyword = withoutNegativeWords(keyword)
  if (shortKeyword === keyword) {
    return keyword
  }

  return (
    <Fragment>
      <span>{shortKeyword}</span>
      <Popup
        trigger={<MoreIcon name="triangle right" color="grey" />}
        on="click"
        content={keyword}
      />
    </Fragment>
  )
}

KeywordText.propTypes = {
  keyword: PropTypes.string.isRequired,
}

export default KeywordText
