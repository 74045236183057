import React from 'react'
import { FETCH_LIST_FAILURE, ProjectsList } from '@airmedia/projects-embed'
import ContentContainer from '../components/ContentContainer'
import RequestError from '../components/RequestError'

const IndexPage = () => (
  <RequestError actionTypes={[FETCH_LIST_FAILURE]}>
    <ContentContainer mode="column" headerContent="Список проектов">
      <ProjectsList />
    </ContentContainer>
  </RequestError>
)

export default IndexPage
