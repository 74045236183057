import React from 'react'
import PropTypes from 'prop-types'
import { Container, Grid, Header } from 'semantic-ui-react'
import styled from 'styled-components/macro'

const ContentContainer = ({ headerContent, children, className, mode }) => {
  if (mode === 'text') {
    return (
      <Container className={className} text>
        <Header as="h3" content={headerContent} />
        {children}
      </Container>
    )
  }

  if (mode === 'column') {
    return (
      <Container className={className}>
        <Grid columns={1} centered>
          <Grid.Row>
            <Grid.Column width={10}>
              <Header as="h3" content={headerContent} />
              {children}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    )
  }

  return (
    <Container className={className}>
      <Header as="h3" content={headerContent} />
      {children}
    </Container>
  )
}

ContentContainer.defaultProps = {
  children: null,
  className: null,
  mode: 'column',
}

ContentContainer.propTypes = {
  headerContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  mode: PropTypes.oneOf(['column', 'wide', 'text']),
}

export default styled(ContentContainer)`
  padding-top: 50px;
  padding-bottom: 50px;
`
