import * as types from './constants'

export { requestFailure, requestSuccess } from '../actions'

export const loadLogEventsPage = (projectId, page = 1, filters = {}) => ({
  type: types.FETCH_PAGE_REQUEST,
  projectId,
  page,
  filters,
})

export const resetLogData = () => ({
  type: types.RESET,
})
