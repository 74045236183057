import isObject from 'lodash/isPlainObject'
import uniq from 'lodash/uniq'

const toString = value => {
  if (Array.isArray(value)) {
    return value
      .slice()
      .sort()
      .join(',')
  }
  return (value || '').toString()
}

export default (left, right) => {
  if (!isObject(left) || !isObject(right)) {
    return false
  }
  const allKeys = uniq(Object.keys(left).concat(Object.keys(right)))
  return allKeys.every(key => toString(left[key]) === toString(right[key]))
}
