import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { requestFailure, requestSuccess } from '../actions'
import * as types from './constants'
import * as api from '../../api'

const getNextPageOffset = ({ total, limit, offset }) => {
  if (total > 0 && offset < total) {
    return limit + offset
  }
  return null
}

export function* createTask({ projectId, formData, resolve, reject }) {
  try {
    const payload = yield call(api.createTask, projectId, formData)
    yield put(
      requestSuccess(types.CREATE_SUCCESS, payload, {
        projectId,
      }),
    )
    yield call(resolve)
  } catch (err) {
    yield put(
      requestFailure(types.CREATE_FAILURE, err, {
        projectId,
      }),
    )
    yield call(reject, err)
  }
}

export function* fetchTasks({ projectId, offset }) {
  try {
    const payload = yield call(api.fetchTasks, projectId, offset)
    yield put(
      requestSuccess(types.FETCH_LIST_SUCCESS, payload, {
        projectId,
        nextPageOffset: getNextPageOffset(payload.meta || {}),
      }),
    )
  } catch (err) {
    yield put(
      requestFailure(types.FETCH_LIST_FAILURE, err, {
        projectId,
      }),
    )
  }
}

export function* fetchTask({ projectId, taskId }) {
  try {
    const payload = yield call(api.fetchTask, projectId, taskId)
    yield put(
      requestSuccess(types.FETCH_TASK_SUCCESS, payload, {
        taskId,
        projectId,
      }),
    )
  } catch (err) {
    yield put(
      requestFailure(types.FETCH_TASK_FAILURE, err, {
        taskId,
        projectId,
      }),
    )
  }
}

export function* enableSync({ projectId, taskId }) {
  try {
    yield call(api.enableSync, projectId, taskId)
    yield put(
      requestSuccess(
        types.ENABLE_SYNC_SUCCESS,
        {},
        {
          taskId,
          projectId,
        },
      ),
    )
  } catch (err) {
    yield put(
      requestFailure(types.ENABLE_SYNC_FAILURE, err, {
        taskId,
        projectId,
      }),
    )
  }
}

export function* updateTask({ projectId, taskId, payload }) {
  try {
    const respPayload = yield call(api.updateTask, projectId, taskId, payload)
    yield put(
      requestSuccess(types.UPDATE_TASK_SUCCESS, respPayload, {
        projectId,
        taskId,
      }),
    )
  } catch (err) {
    yield put(
      requestFailure(types.UPDATE_TASK_FAILURE, err, {
        projectId,
        taskId,
      }),
    )
  }
}

function* watchCreateTask() {
  yield takeEvery(types.CREATE_REQUEST, createTask)
}

function* watchFetchTasks() {
  yield takeEvery(types.FETCH_LIST_REQUEST, fetchTasks)
}

function* watchFetchTask() {
  yield takeEvery(types.FETCH_TASK_REQUEST, fetchTask)
}

function* watchEnableSync() {
  yield takeLatest(types.ENABLE_SYNC_REQUEST, enableSync)
}

function* watchUpdateTask() {
  yield takeEvery(types.UPDATE_TASK_REQUEST, updateTask)
}

export function* tasksSaga() {
  yield all([
    watchCreateTask(),
    watchEnableSync(),
    watchFetchTask(),
    watchFetchTasks(),
    watchUpdateTask(),
  ])
}
