import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Dimmer, Loader, Message } from 'semantic-ui-react'
import { Pagination } from 'pannier'
import { getReportItems, getTotalPages, isFetching } from '../../../../stores/report'
import ReportTable from './components/ReportTable'

const mapStateToProps = state => ({
  isFetching: isFetching(state),
  items: getReportItems(state),
  totalPages: getTotalPages(state),
})

const ReportResults = ({ items, totalPages, currentPage, isFetching, setPage, filters }) => {
  const selectedRelevances = filters.relevance || []
  const matchRelevance = relevance =>
    selectedRelevances.length === 0 || selectedRelevances.includes(relevance)

  return (
    <Dimmer.Dimmable>
      <Dimmer active={isFetching} inverted>
        <Loader active size="large" />
      </Dimmer>
      {items.length > 0 && (
        <Fragment>
          <ReportTable items={items} matchRelevance={matchRelevance} />
          <Pagination totalPages={totalPages} activePage={currentPage} onChange={setPage} />
        </Fragment>
      )}
      {!items.length && !isFetching && (
        <Message
          info
          icon="frown outline"
          content="К сожалению, ничего не найдено. Попробуйте изменить условия поиска."
        />
      )}
    </Dimmer.Dimmable>
  )
}

ReportResults.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  filters: PropTypes.shape({
    relevance: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
}

export default connect(mapStateToProps)(ReportResults)
