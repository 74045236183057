import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Icon, Popup } from 'semantic-ui-react'
import styled from 'styled-components/macro'
import * as statuses from '../../../../../constants/keywordStatuses'
import HelpIcon from '../../../../components/HelpIcon'

const getIconProps = status => {
  switch (status) {
    case statuses.UNRESOLVED:
      return { name: 'exclamation circle', color: 'orange' }
    case statuses.PENDING:
      return { name: 'sync', color: 'teal' }
    case statuses.FAILED:
      return { name: 'times circle', color: 'red' }
    case statuses.ADDED:
      return { name: 'plus circle', color: 'green' }
    case statuses.MERGED:
      return { name: 'check circle', color: 'violet' }
    case statuses.DUPLICATE:
      return { name: 'exclamation circle', color: 'yellow' }
    case statuses.CONFLICT:
    case statuses.BROKEN_GROUP:
      return { name: 'exclamation circle', color: 'red' }
    case statuses.EXCLUDED:
      return { name: 'ban', color: 'orange' }
    default:
      return { name: 'frown', color: 'grey' }
  }
}

const ItemStatus = ({ className, syncStatus, mergeWith }) => (
  <div className={className}>
    <Icon {...getIconProps(syncStatus)} />
    {statuses.translate(syncStatus)}
    {mergeWith && (
      <Popup
        trigger={<HelpIcon />}
        on="click"
        content={
          <Fragment>
            <p>
              <strong>Номер:</strong> {mergeWith.id}
            </p>
            <p>
              <strong>Ключевая фраза:</strong> {mergeWith.keyword}
            </p>
          </Fragment>
        }
      />
    )}
  </div>
)

ItemStatus.defaultProps = {
  syncStatus: null,
  mergeWith: null,
}

ItemStatus.propTypes = {
  className: PropTypes.string.isRequired,
  syncStatus: PropTypes.oneOf(statuses.list()),
  mergeWith: PropTypes.shape({
    id: PropTypes.number.isRequired,
    keyword: PropTypes.string.isRequired,
  }),
}

const StyledItemStatus = styled(ItemStatus)`
  white-space: nowrap;
`

StyledItemStatus.WrappedComponent = ItemStatus

export default StyledItemStatus
