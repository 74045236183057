import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'semantic-ui-react'

const ResettableInput = ({ onReset, name, value, ...rest }) => {
  const resetIcon = value
    ? {
        name: 'remove',
        color: 'grey',
        link: true,
        onClick: onReset
          ? () => {
              onReset({ name })
            }
          : null,
      }
    : null
  return <Form.Input {...rest} name={name} value={value} icon={resetIcon} />
}

ResettableInput.defaultProps = {
  onReset: null,
  value: null,
}

ResettableInput.propTypes = {
  onReset: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
}

export default ResettableInput
