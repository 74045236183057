export const requestSuccess = (type, payload, extra = {}) => ({
  type,
  ...extra,
  ...payload,
})

export const requestFailure = (type, error, extra = {}) => ({
  type,
  ...extra,
  error,
})
