import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import * as types from './constants'
import { makeMergeEntities, resetEntities, setFalse, setTrue } from '../actionHandlers'

export * from './actions'
export * from './constants'
export * from './selectors'
export { logEventsSaga } from './sagas'

export default combineReducers({
  entryById: handleActions(
    {
      [types.FETCH_PAGE_REQUEST]: resetEntities,
      [types.FETCH_PAGE_SUCCESS]: makeMergeEntities('logEntries'),
    },
    {},
  ),
  ids: handleActions(
    {
      [types.FETCH_PAGE_SUCCESS]: (state, action) => action.result,
    },
    [],
  ),
  userById: handleActions(
    {
      [types.FETCH_PAGE_SUCCESS]: makeMergeEntities('users'),
    },
    {},
  ),
  isFetching: handleActions(
    {
      [types.FETCH_PAGE_REQUEST]: setTrue,
      [types.FETCH_PAGE_SUCCESS]: setFalse,
      [types.FETCH_PAGE_FAILURE]: setFalse,
    },
    false,
  ),
  totalPages: handleActions(
    {
      [types.FETCH_PAGE_SUCCESS]: (state, action) => action.totalPages,
    },
    0,
  ),
})
