import { createSelector } from 'reselect'

const getGroupsById = state => state.report.groupById
const getKeywordsById = state => state.report.keywordById
const getRarelyServedGroupsById = state => state.report.rsGroupById
const getIds = state => state.report.ids

export const isFetching = state => state.report.isFetching
export const getTotalPages = state => state.report.totalPages
export const getTotalByStatuses = state => state.report.totalByStatus
export const getTotalByRelevances = state => state.report.totalByRelevance

export const getReportItems = createSelector(
  getIds,
  getGroupsById,
  getKeywordsById,
  getRarelyServedGroupsById,
  (ids, groups, keywords, rarelyServedGroups) =>
    ids.map(item => {
      if (item.schema === 'group') {
        const group = groups[item.id]
        return {
          ...group,
          rarelyServedKeywords: (group.rarelyServedKeywords || []).map(keyword => ({
            ...keyword,
            group: rarelyServedGroups[keyword.group],
          })),
        }
      }
      if (item.schema === 'keyword') {
        const keyword = keywords[item.id]
        return {
          ...keyword,
          group: rarelyServedGroups[keyword.group],
        }
      }
      return item
    }),
)
