import React from 'react'
import { Table } from 'semantic-ui-react'
import ItemStatus from './ItemStatus'
import * as statuses from '../../../../../constants/keywordStatuses'

const legend = {
  [statuses.PENDING]:
    'Для фразы успешно подобрана новая группа. При синхронизации фраза будет добавлена в эту группу.',
  [statuses.UNRESOLVED]:
    'Для фразы не удалось подобрать подходящую группу-получатель. Возможно, в РК нет релевантных групп или в соответствующих группах нет места для новых ключей.',
  [statuses.ADDED]: 'Фраза успешно добавлена в выбранную группу.',
  [statuses.MERGED]: 'Фраза была объединена с одной из существующих фраз в группе-получателе.',
  [statuses.FAILED]: 'При синхронизации фразы возникла ошибка.',
  [statuses.DUPLICATE]: 'Фраза является дубликатом другой фразы в этой группе.',
  [statuses.CONFLICT]:
    'Синхронизация не возможна, так как после импорта данных для соответствующей фразы или группы в Директе были внесены изменения.',
  [statuses.BROKEN_GROUP]:
    'Фразы группы содержат дубли или не переминусованы. Требуется корректировка фраз для участия группы в перегруппировке.',
  [statuses.EXCLUDED]: 'Фраза исключена согласно настройкам синхронизации.',
}

export default () => (
  <Table basic="very" compact collapsing>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell width={3}>Статус</Table.HeaderCell>
        <Table.HeaderCell width={13}>Описание</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {Object.entries(legend).map(([status, description]) => (
        <Table.Row key={status}>
          <Table.Cell>
            <ItemStatus syncStatus={status} />
          </Table.Cell>
          <Table.Cell>{description}</Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
)
