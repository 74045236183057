import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components/macro'
import { Message, Icon } from 'semantic-ui-react'
import { fetchProject, getProject } from '@airmedia/projects-embed'
import { yandexNotConnected } from '../../utils/notConnected'

const mapStateToProps = (
  state,
  {
    match: {
      params: { projectId },
    },
  },
) => ({
  project: getProject(state, projectId),
})

const MessageWrap = styled.div`
  margin-top: 10px;
  text-align: center;
`

class YandexNotAvailableMessage extends Component {
  state = {
    canFetch: false,
    projectId: null, // eslint-disable-line react/no-unused-state
  }

  static getDerivedStateFromProps(props, state) {
    const { project } = props
    if (!project) {
      return {
        canFetch: false,
        projectId: null,
      }
    }
    if (project.id !== state.projectId) {
      return {
        canFetch: project.id !== state.projectId && !project.isFetching,
        projectId: project.id,
      }
    }
    if (project.isFetching) {
      return {
        canFetch: false,
      }
    }
    return null
  }

  componentDidMount() {
    this.loadProject()
  }

  componentDidUpdate() {
    this.loadProject()
  }

  loadProject() {
    const { fetchProject, project } = this.props
    const { canFetch } = this.state
    if (canFetch && project.id && !project.yandexDirectStatus) {
      fetchProject(project.id)
    }
  }

  render() {
    const { project } = this.props
    if (project && yandexNotConnected(project)) {
      return (
        <MessageWrap>
          <Message warning compact>
            <Message.Content>
              <Icon name="warning circle" />
              Работа с Яндекс.Директом в настоящий момент невозможна. Подключить рекламную систему
              можно на странице с настройками проекта.
            </Message.Content>
          </Message>
        </MessageWrap>
      )
    }

    return null
  }
}

YandexNotAvailableMessage.defaultProps = {
  project: null,
}

YandexNotAvailableMessage.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string,
    yandexDirectStatus: PropTypes.string,
  }),
  fetchProject: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  {
    fetchProject,
  },
)(YandexNotAvailableMessage)
