import React from 'react'
import { FETCH_DETAILS_FAILURE as FETCH_PROJECT_FAILURE } from '@airmedia/projects-embed'

import NewTaskForm from './components/NewTaskForm'
import ContentContainer from '../components/ContentContainer'
import RequestError from '../components/RequestError'

const NewTaskPage = () => (
  <RequestError actionTypes={[FETCH_PROJECT_FAILURE]}>
    <ContentContainer headerContent="Новая задача">
      <NewTaskForm />
    </ContentContainer>
  </RequestError>
)

export default NewTaskPage
