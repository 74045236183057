import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { generatePath, withRouter } from 'react-router'
import { format } from 'date-fns'
import NewTaskForm from './components/NewTaskForm'
import { createTask } from '../../../../stores/tasks'
import * as routes from '../../../../constants/routes'
import { makeErrorHandler } from '../../../../utils/form'

const handleError = makeErrorHandler({
  errorMapping: formPath => {
    if (/^excludeTemplates\[\d+]/.test(formPath)) {
      return 'excludeTemplates'
    }
    return formPath
  },
})

const TaskReduxForm = reduxForm({
  form: 'newTask',
})(NewTaskForm)

class NewTaskFormContainer extends Component {
  constructor(props) {
    super(props)
    const today = format(new Date(), 'DD.MM.YYYY')
    this.state = {
      defaultName: `Перегруппировка от ${today}`,
    }
  }

  redirect = () => {
    const {
      history,
      match: { params },
    } = this.props
    history.push(generatePath(routes.TASKS_LIST, params))
  }

  handleSubmit = ({ name, excludeTemplates }) => {
    const data = {
      name: name || this.state.defaultName,
      excludeTemplates: excludeTemplates || [],
    }
    const {
      createTask,
      match: {
        params: { projectId },
      },
    } = this.props

    return new Promise((resolve, reject) => createTask(projectId, data, resolve, reject))
      .then(this.redirect)
      .catch(err => handleError(err, data))
  }

  render() {
    const {
      match: {
        params: { projectId },
      },
    } = this.props

    return (
      <TaskReduxForm
        projectId={projectId}
        defaultName={this.state.defaultName}
        onSubmit={this.handleSubmit}
        onCancel={this.redirect}
      />
    )
  }
}

NewTaskFormContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  createTask: PropTypes.func.isRequired,
}

export const connectedNewTaskFormContainer = connect(
  null,
  { createTask },
)(NewTaskFormContainer)

export default withRouter(connectedNewTaskFormContainer)
