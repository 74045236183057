import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { notifications } from 'pannier'
import { reducer as projects } from '@airmedia/projects-embed'
import user, { LOGOUT } from './user'
import campaigns from './campaigns'
import tasks from './tasks'
import report, { RESET as REPORT_RESET } from './report'
import log, { RESET as LOG_RESET } from './log'
import requestErrors from './requestErrors'

const appReducer = combineReducers({
  form: formReducer,
  notifications,
  requestErrors,
  projects,
  campaigns,
  tasks,
  report,
  log,
  user,
})

const resetState = (state, action) => {
  switch (action.type) {
    case LOGOUT:
      return undefined
    case REPORT_RESET:
      return {
        ...state,
        report: undefined,
      }
    case LOG_RESET:
      return {
        ...state,
        log: undefined,
      }
    default:
      return state
  }
}

export default (state, action) => appReducer(resetState(state, action), action)
