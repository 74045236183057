import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'semantic-ui-react'
import ContentContainer from '../../ContentContainer'

const ErrorPage = ({ title, message, children }) => (
  <ContentContainer headerContent={title} mode="text">
    {message && (
      <p>
        <Icon name="times circle" size="big" color="red" />
        {message}
      </p>
    )}
    {children}
  </ContentContainer>
)

ErrorPage.defaultProps = {
  message: null,
  children: null,
}

ErrorPage.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  children: PropTypes.node,
}

export default ErrorPage
