import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { generatePath, Redirect } from 'react-router'
import { ApiError, RequestError as ApiRequestError } from '@airmedia/api-utils'
import * as routes from '../../../constants/routes'
import { getRequestError, resetErrors } from '../../../stores/requestErrors'
import ErrorPage from './components/ErrorPage'

const supportError = error => error instanceof ApiError || error instanceof ApiRequestError

const mapStateToProps = (state, { actionTypes }) => {
  const errors = actionTypes.map(type => getRequestError(state, type)).filter(supportError)

  return {
    httpStatus: errors.length ? errors[0].status : null,
  }
}

class RequestError extends Component {
  componentDidMount() {
    if (this.props.resetOnMount) {
      this.reset()
    }
  }

  componentWillUnmount() {
    this.reset()
  }

  reset() {
    const { resetErrors, actionTypes } = this.props
    resetErrors(actionTypes)
  }

  render() {
    const { httpStatus, children, location } = this.props

    if (httpStatus === 401 && location) {
      return (
        <Redirect
          to={{
            pathname: generatePath(routes.AUTH),
            state: { from: location },
          }}
        />
      )
    }

    if (httpStatus === 403) {
      return (
        <ErrorPage
          title="Недостаточно прав"
          message="К сожалению, у вас недостаточно прав для просмотра страницы."
        />
      )
    }

    if (httpStatus === 404) {
      return (
        <ErrorPage
          title="Страница не найдена"
          message="Запрашиваемая страница не найдена или была удалена."
        />
      )
    }

    if (httpStatus >= 500) {
      return (
        <ErrorPage
          title="Ресурс временно недоступен"
          message="Запрашиваемый ресурс временно недоступен. Попробуйте обновить страницу через некоторое время. Если ошибка сохранится, обратитесь в поддержку."
        />
      )
    }

    return children
  }
}

RequestError.defaultProps = {
  httpStatus: null,
  location: null,
  resetOnMount: false,
}

RequestError.propTypes = {
  resetErrors: PropTypes.func.isRequired,
  actionTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  httpStatus: PropTypes.number,
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    hash: PropTypes.string,
  }),
  resetOnMount: PropTypes.bool,
}

export default connect(
  mapStateToProps,
  { resetErrors },
)(RequestError)
