import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'
import KeywordText from './KeywordText'
import ItemStatus from './ItemStatus'
import { list as allowedStatuses } from '../../../../../constants/keywordStatuses'

const KeywordRow = ({ group, keyword, id, status, mergeWith }) => (
  <Table.Row>
    <Table.Cell>{group && group.campaignId}</Table.Cell>
    <Table.Cell>{group && group.id}</Table.Cell>
    <Table.Cell>{id}</Table.Cell>
    <Table.Cell>
      <KeywordText keyword={keyword} />
    </Table.Cell>
    <Table.Cell />
    <Table.Cell />
    <Table.Cell>
      <ItemStatus syncStatus={status} mergeWith={mergeWith} />
    </Table.Cell>
  </Table.Row>
)

KeywordRow.defaultProps = {
  status: null,
  mergeWith: null,
}

KeywordRow.propTypes = {
  id: PropTypes.number.isRequired,
  keyword: PropTypes.string.isRequired,
  group: PropTypes.shape({
    id: PropTypes.number.isRequired,
    campaignId: PropTypes.number.isRequired,
  }).isRequired,
  status: PropTypes.oneOf(allowedStatuses()),
  mergeWith: PropTypes.shape({
    id: PropTypes.number.isRequired,
    keyword: PropTypes.string.isRequired,
  }),
}

export default KeywordRow
