import { camelizeKeys, decamelizeKeys } from 'humps'
import { schema } from 'normalizr'
import { buildUrl } from '@airmedia/api-utils'
import callApi from './callApi'

const PAGE_SIZE = 20

const taskSchema = new schema.Entity(
  'tasks',
  {},
  {
    processStrategy: camelizeKeys,
  },
)

const eligibleGroupSchema = new schema.Entity(
  'eligibleGroups',
  {},
  {
    processStrategy: camelizeKeys,
  },
)

const rarelyServedGroupSchema = new schema.Entity(
  'rarelyServedGroups',
  {},
  {
    processStrategy: camelizeKeys,
  },
)

const rarelyServedKeywordSchema = new schema.Entity(
  'rarelyServedKeywords',
  {},
  {
    processStrategy: camelizeKeys,
  },
)

const logEntrySchema = new schema.Entity(
  'logEntries',
  {},
  {
    processStrategy: camelizeKeys,
  },
)

const userSchema = new schema.Entity(
  'users',
  {},
  {
    processStrategy: camelizeKeys,
  },
)

const campaignSchema = new schema.Entity(
  'campaigns',
  {},
  {
    processStrategy: camelizeKeys,
  },
)

const reportResponseSchema = new schema.Object({
  data: new schema.Array(
    {
      keyword: rarelyServedKeywordSchema,
      group: eligibleGroupSchema,
    },
    input => input.type,
  ),
  included: {
    rarely_served_groups: new schema.Array(rarelyServedGroupSchema),
  },
})

const logResponseSchema = new schema.Object({
  data: new schema.Array(logEntrySchema),
  included: {
    users: new schema.Array(userSchema),
  },
})

const buildFilterParams = (filter, allowKeys) =>
  Object.entries(filter).reduce((acc, [key, value]) => {
    if (allowKeys.includes(key)) {
      acc[key] = Array.isArray(value) ? value.join(',') : value
    }
    return acc
  }, {})

const reshapePayload = ({ entities, result }) => ({
  entities,
  result: result.data,
  meta: result.meta,
})

export const fetchTasks = (projectId, offset = 0) => {
  const endpoint = buildUrl(`/api/${projectId}/tasks`, {
    limit: PAGE_SIZE,
    offset,
  })
  return callApi('GET', endpoint, {
    schema: [taskSchema],
  })
}

export const fetchTask = (projectId, taskId) => {
  const endpoint = `/api/${projectId}/tasks/${taskId}`
  return callApi('GET', endpoint, {
    schema: taskSchema,
  })
}

export const createTask = (projectId, data) => {
  const endpoint = buildUrl(`/api/${projectId}/tasks`)
  return callApi('POST', endpoint, {
    data: decamelizeKeys(data),
    schema: taskSchema,
  })
}

export const updateTask = (projectId, taskId, data) => {
  const endpoint = buildUrl(`/api/${projectId}/tasks/${taskId}`)
  return callApi('PATCH', endpoint, {
    data: decamelizeKeys(data),
    schema: taskSchema,
  })
}

export const enableSync = (projectId, taskId) =>
  callApi('PUT', `/api/${projectId}/tasks/${taskId}/sync`)

export const fetchReport = (projectId, taskId, filter = {}, limit = PAGE_SIZE, offset = 0) => {
  const endpoint = buildUrl(`/api/${projectId}/tasks/${taskId}/report`, {
    limit,
    offset,
    ...buildFilterParams(filter, ['status', 'campaign', 'group', 'keyword', 'relevance']),
  })
  return callApi('GET', endpoint, {
    respSchema: reportResponseSchema,
  }).then(reshapePayload)
}

export const fetchLog = (projectId, filter = {}, limit = PAGE_SIZE, offset = 0) => {
  const endpoint = buildUrl(`/api/${projectId}/log`, {
    limit,
    offset,
    ...buildFilterParams(filter, ['task', 'from', 'to', 'campaign', 'group', 'keyword']),
  })
  return callApi('GET', endpoint, {
    respSchema: logResponseSchema,
  }).then(reshapePayload)
}

export const fetchCampaigns = (projectId, taskId = null) => {
  const endpoint = buildUrl(`/api/${projectId}/yandex/campaigns`, taskId ? { task: taskId } : null)
  return callApi('GET', endpoint, {
    schema: [campaignSchema],
  })
}
