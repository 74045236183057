import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import { isPlainObject, uniq } from 'lodash'
import * as types from './constants'
import { makeMergeEntities, resetEntities, setFalse, setTrue } from '../actionHandlers'

export * from './actions'
export * from './constants'
export * from './selectors'
export { reportSaga } from './sagas'

const totalsIsEqual = (prevTotals, totals) => {
  if (isPlainObject(prevTotals) || isPlainObject(totals)) {
    const allKeys = uniq(Object.keys(prevTotals).concat(Object.keys(totals)))
    return allKeys.every(key => prevTotals[key] === totals[key])
  }
  return false
}

const updateTotals = (state, totals) => (totalsIsEqual(state, totals) ? state : totals)

export default combineReducers({
  groupById: handleActions(
    {
      [types.FETCH_PAGE_REQUEST]: resetEntities,
      [types.FETCH_PAGE_SUCCESS]: makeMergeEntities('eligibleGroups'),
    },
    {},
  ),
  keywordById: handleActions(
    {
      [types.FETCH_PAGE_REQUEST]: resetEntities,
      [types.FETCH_PAGE_SUCCESS]: makeMergeEntities('rarelyServedKeywords'),
    },
    {},
  ),
  ids: handleActions(
    {
      [types.FETCH_PAGE_SUCCESS]: (state, action) => action.result,
    },
    [],
  ),
  rsGroupById: handleActions(
    {
      [types.FETCH_PAGE_SUCCESS]: makeMergeEntities('rarelyServedGroups'),
    },
    {},
  ),
  isFetching: handleActions(
    {
      [types.FETCH_PAGE_REQUEST]: setTrue,
      [types.FETCH_PAGE_SUCCESS]: setFalse,
      [types.FETCH_PAGE_FAILURE]: setFalse,
    },
    false,
  ),
  totalPages: handleActions(
    {
      [types.FETCH_PAGE_SUCCESS]: (state, action) => action.totalPages,
    },
    0,
  ),
  totalByStatus: handleActions(
    {
      [types.SET_STATS_BY_STATUS]: (state, action) => updateTotals(state, action.stats),
    },
    {},
  ),
  totalByRelevance: handleActions(
    {
      [types.SET_STATS_BY_RELEVANCE]: (state, action) => updateTotals(state, action.stats),
    },
    {},
  ),
})
