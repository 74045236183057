import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Modal, Table, Popup } from 'semantic-ui-react'
import GroupRow from './GroupRow'
import KeywordRow from './KeywordRow'
import ExplanationStatuses from './ExplanationStatuses'
import HelpIcon from '../../../../components/HelpIcon'

const StyledTable = styled(Table)`
  &.celled.table thead {
    th {
      white-space: nowrap;
    }

    tr ~ tr th:first-child {
      border-left: 1px solid rgba(34, 36, 38, 0.1);
    }
  }
`

const ReportTable = ({ items, matchRelevance }) => (
  <StyledTable celled compact>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell width={1} rowSpan={2} textAlign="center">
          № кампании
        </Table.HeaderCell>
        <Table.HeaderCell colSpan={3} textAlign="center">
          Группы-доноры с &quot;мало показов&quot;
        </Table.HeaderCell>
        <Table.HeaderCell colSpan={2} textAlign="center">
          Группы-получатели
        </Table.HeaderCell>
        <Table.HeaderCell width={3} rowSpan={2} textAlign="center">
          Статус{' '}
          <Modal closeIcon trigger={<HelpIcon />}>
            <Modal.Content>
              <ExplanationStatuses />
            </Modal.Content>
          </Modal>
        </Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <Table.HeaderCell width={1} textAlign="center">
          № группы
        </Table.HeaderCell>
        <Table.HeaderCell width={1} textAlign="center">
          № ключа
        </Table.HeaderCell>
        <Table.HeaderCell width={6} textAlign="center">
          Ключ к обработке
        </Table.HeaderCell>
        <Table.HeaderCell width={3} textAlign="center">
          Мастер-ключ группы
          <Popup on="click" wide trigger={<HelpIcon />}>
            <Popup.Content>
              Это общий признак ключей из группы-получателя. <strong>Мастер-ключ</strong> может быть
              одним словом или фразой, общим для большинства ключей группы-получателя.
              Он не обязательно полностью совпадает с какой-то ключевой фразой из группы-получателя.
            </Popup.Content>
          </Popup>
        </Table.HeaderCell>
        <Table.HeaderCell width={1} textAlign="center">
          № группы
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {items.map(item => {
        switch (item.type) {
          case 'keyword':
            return <KeywordRow key={`${item.id}-${item.type}`} {...item} />
          case 'group':
            return (
              <GroupRow key={`${item.id}-${item.type}`} {...item} matchRelevance={matchRelevance} />
            )
          default:
            return null
        }
      })}
    </Table.Body>
  </StyledTable>
)

ReportTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.oneOf(['keyword', 'group']).isRequired,
    }),
  ).isRequired,
  matchRelevance: PropTypes.func.isRequired,
}

export default ReportTable
