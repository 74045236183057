import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as api from 'auth-component'
import * as types from './constants'

export function* signOut() {
  yield call(api.signOut)
}

export function* fetchProfile() {
  try {
    const payload = yield call(api.fetchProfile)
    return yield put({ type: types.FETCH_PROFILE_SUCCESS, payload })
  } catch (error) {
    yield put({ type: types.FETCH_PROFILE_FAILURE, error })
  }

  return null
}

function* watchFetchProfile() {
  yield takeLatest(types.FETCH_PROFILE_REQUEST, fetchProfile)
}

function* watchSignOut() {
  yield takeLatest(types.LOGOUT, signOut)
}

export function* userSaga() {
  yield all([watchFetchProfile(), watchSignOut()])
}
