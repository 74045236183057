import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import * as types from './constants'
import { makeBooleanFlagReducer } from '../makeReducer'

export * from './actions'
export * from './constants'
export * from './selectors'
export { userSaga } from './sagas'

export default combineReducers({
  profile: handleActions(
    {
      [types.FETCH_PROFILE_SUCCESS]: (state, action) => action.payload,
    },
    null,
  ),
  isFetching: makeBooleanFlagReducer(
    types.FETCH_PROFILE_REQUEST,
    types.FETCH_PROFILE_SUCCESS,
    types.FETCH_PROFILE_FAILURE,
  ),
})
