import { SubmissionError } from 'redux-form'
import get from 'lodash/get'
import { camelize, decamelize } from 'humps'
import { ApiError } from '@airmedia/api-utils'
import getMessage from '../messages'
import setPathValue from './setPathValue'
import logError from './logError'

const getServerErrors = error => {
  if (error instanceof ApiError && error.status === 422) {
    return error.payload.errors || []
  }
  return []
}

const generateSubmitError = error => {
  if (error) {
    const { status } = error

    if (status >= 400 && status < 500) {
      return getMessage('form.submit.error_4xx', { status })
    }

    if (status >= 500) {
      return getMessage('form.submit.error_5xx', { status })
    }
  }

  return getMessage('form.submit.unk_error')
}

export const getSubmitError = (error, submitFailed) => {
  if (error) {
    return error
  }

  return submitFailed ? getMessage('form.submit.error') : null
}

export const errorPathToFormPath = path => camelize(path.replace(/^data(\.|$)/, ''))

export const formPathToMessageId = (path, formPrefix) => {
  const key = path.replace(/(\w+)(\[\d+])+/g, '$1')
  return decamelize(`${formPrefix}.${key}`)
}

const defaultOptions = {
  canMapped: (formValue, formPath) => formPath && formValue !== undefined,
  errorMapping: formPath => formPath,
  errorMessagePrefix: 'form.error',
  normalizeFormPath: formPath => formPath,
}

export const makeErrorHandler = (options = {}) => (error, formValues) => {
  const serverErrors = getServerErrors(error)
  if (!serverErrors.length) {
    logError(error, {
      message: 'Form submit failed.',
    })

    throw new SubmissionError({
      _error: generateSubmitError(error),
    })
  }

  const handlerOptions = {
    ...defaultOptions,
    ...options,
  }
  const unmappedErrors = []
  const formErrors = {}

  serverErrors.forEach(err => {
    const formPath = handlerOptions.normalizeFormPath(errorPathToFormPath(err.property))
    const formValue = get(formValues, formPath)
    if (handlerOptions.canMapped(formValue, formPath, err)) {
      const messageId = formPathToMessageId(formPath, handlerOptions.errorMessagePrefix)
      const message = getMessage(messageId, {
        formPath,
        value: get(formValues, formPath),
        code: err.code,
        params: err.params,
      })

      setPathValue(formErrors, handlerOptions.errorMapping(formPath), message)
    } else {
      unmappedErrors.push(err)
    }
  })

  if (unmappedErrors.length) {
    logError(error, {
      message: 'Form submit failed. Some server errors unmapped.',
      unmappedErrors,
    })
  }

  throw new SubmissionError({
    _error: getMessage(unmappedErrors.length ? 'form.submit.unk_error' : 'form.submit.error'),
    ...formErrors,
  })
}
