import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'
import styled from 'styled-components/macro'
import KeywordText from './KeywordText'
import ItemStatus from './ItemStatus'
import { list as allowedStatuses } from '../../../../../constants/keywordStatuses'
import RelevanceScore from './RelevanceScore'

const KeywordWrap = styled.div`
  display: flex;
  justify-content: space-between;

  .relevance {
    padding-left: 0.7em;

    .icon {
      opacity: 0.1;
    }

    &.active .icon {
      opacity: 0.6;
    }
  }
`

const GroupRow = ({
  className,
  id: groupId,
  campaignId,
  masterKeyword,
  rarelyServedKeywords,
  matchRelevance,
}) =>
  rarelyServedKeywords.map(({ id, keyword, group, status, mergeWith, relevance }, index) => (
    <Table.Row className={className} key={id}>
      {index === 0 && <Table.Cell rowSpan={rarelyServedKeywords.length}>{campaignId}</Table.Cell>}
      <Table.Cell>{group && group.id}</Table.Cell>
      <Table.Cell>{id}</Table.Cell>
      <Table.Cell>
        <KeywordWrap>
          <div>
            <KeywordText keyword={keyword} />
          </div>
          {relevance && (
            <div className={`relevance ${(matchRelevance(relevance) && 'active') || ''}`}>
              <RelevanceScore relevance={relevance} />
            </div>
          )}
        </KeywordWrap>
      </Table.Cell>
      {index === 0 && (
        <Table.Cell rowSpan={rarelyServedKeywords.length}>{masterKeyword}</Table.Cell>
      )}
      {index === 0 && <Table.Cell rowSpan={rarelyServedKeywords.length}>{groupId}</Table.Cell>}
      <Table.Cell>
        <ItemStatus syncStatus={status} mergeWith={mergeWith} />
      </Table.Cell>
    </Table.Row>
  ))

GroupRow.propTypes = {
  className: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  campaignId: PropTypes.number.isRequired,
  masterKeyword: PropTypes.string,
  rarelyServedKeywords: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      keyword: PropTypes.string.isRequired,
      group: PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
      status: PropTypes.oneOf(allowedStatuses()),
      mergreWith: PropTypes.shape({
        id: PropTypes.number.isRequired,
        keyword: PropTypes.string.isRequired,
      }),
    }),
  ).isRequired,
  matchRelevance: PropTypes.func.isRequired,
}

export default styled(GroupRow)`
  & td:first-child:not([rowspan]) {
    border-left: 1px solid rgba(34, 36, 38, 0.1) !important;
  }
`
