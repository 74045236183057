import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { intersection, isEqual } from 'lodash'
import memoize from 'memoize-one'
import { StatusFilter } from 'pannier'
import { Grid } from 'semantic-ui-react'
import { getTotalByRelevances } from '../../../stores/report'
import { list, translate } from '../../../constants/relevances'

const mapStateToProps = state => ({
  totals: getTotalByRelevances(state),
})

class RelevanceFilter extends Component {
  allowedStatuses = memoize(totals =>
    Object.entries(totals)
      .filter(entry => entry[1] > 0)
      .map(entry => entry[0]),
  )

  componentDidUpdate(prevProps) {
    const { filter, totals, setFilter } = this.props
    if (!isEqual(prevProps.totals, totals) && Object.keys(totals).length > 0) {
      const intersect = intersection(filter || [], this.allowedStatuses(totals))
      setFilter(intersect.length > 0 ? intersect.sort() : null)
    }
  }

  handleFilterChange = (selectedStatuses, allSelected) => {
    const { setFilter } = this.props
    if (allSelected || selectedStatuses.length === 0) {
      setFilter(null)
    } else {
      setFilter(selectedStatuses.sort())
    }
  }

  render() {
    const { className, filter, totals } = this.props
    const allowedStatuses = this.allowedStatuses(totals)
    const intersect = intersection(filter || [], allowedStatuses)

    if (allowedStatuses.length > 0) {
      return (
        <Grid className={className}>
          <Grid.Row>
            <Grid.Column width={2} verticalAlign="middle">
              <strong>Релевантность:</strong>
            </Grid.Column>
            <Grid.Column width={14}>
              <StatusFilter
                filter={intersect.length > 0 ? intersect : allowedStatuses}
                totals={totals}
                setFilter={this.handleFilterChange}
                translateStatus={translate}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )
    }

    return null
  }
}

RelevanceFilter.defaultProps = {
  className: null,
  filter: [],
}

RelevanceFilter.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.arrayOf(PropTypes.oneOf(list())),
  setFilter: PropTypes.func.isRequired,
  totals: PropTypes.objectOf(PropTypes.number).isRequired,
}

export default connect(mapStateToProps)(RelevanceFilter)
