import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { FETCH_DETAILS_FAILURE as FETCH_PROJECT_FAILURE } from '@airmedia/projects-embed'
import {
  FETCH_LIST_FAILURE,
  fetchCampaigns,
  isFetching,
  getCampaigns,
} from '../../stores/campaigns'
import { FETCH_TASK_FAILURE } from '../../stores/tasks'
import PageLayout from './components/PageLayout'
import makeRequestErrorWrapper from '../decorators/makeRequestErrorWrapper'
import withTask from '../decorators/withTask'
import Loading from '../decorators/Loading'

const mapStateToProps = (
  state,
  {
    match: {
      params: { projectId, taskId },
    },
  },
) => ({
  projectId,
  taskId,
  campaigns: getCampaigns(state, taskId),
  isFetching: isFetching(state, taskId),
})

class TaskCampaignsPage extends Component {
  componentWillMount() {
    const { fetchCampaigns, projectId, taskId } = this.props

    fetchCampaigns(projectId, taskId)
  }

  render() {
    const { task, campaigns, isFetching } = this.props
    const selectedCampaigns = campaigns.filter(x => x.selected)

    return <PageLayout task={task} campaigns={selectedCampaigns} isFetching={isFetching} />
  }
}

TaskCampaignsPage.propTypes = {
  taskId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  campaigns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  isFetching: PropTypes.bool.isRequired,
  fetchCampaigns: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  {
    fetchCampaigns,
  },
)(
  compose(
    makeRequestErrorWrapper([FETCH_PROJECT_FAILURE, FETCH_TASK_FAILURE, FETCH_LIST_FAILURE]),
    withTask,
    Loading,
  )(TaskCampaignsPage),
)
