import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Dimmer, List, Loader, Message, Segment, Table } from 'semantic-ui-react'
import ContentContainer from '../../components/ContentContainer'

const hasTemplates = task => task.excludeTemplates && task.excludeTemplates.length > 0

const PageLayout = ({ task, campaigns, isFetching }) => (
  <ContentContainer mode="wide" headerContent="Список рекламных кампаний">
    <Segment>
      <List>
        <List.Item>
          <List.Header>Шаблоны исключений:</List.Header>
        </List.Item>
        {hasTemplates(task) &&
          task.excludeTemplates.map(template => <List.Item key={template}>{template}</List.Item>)}
        {!hasTemplates(task) && <List.Item>—</List.Item>}
      </List>
    </Segment>
    <Dimmer.Dimmable>
      <Dimmer active={isFetching} inverted>
        <Loader active size="large" />
      </Dimmer>
      {campaigns.length > 0 && (
        <Fragment>
          <p>
            <strong>Всего кампаний:</strong> {campaigns.length}
            <Message size="mini">
              В списке представлены РК, где есть хотя бы одна группа объявлений со статусом{' '}
              <em>Мало показов</em>.
            </Message>
          </p>
          <Table celled compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1}>№ кампании</Table.HeaderCell>
                <Table.HeaderCell width={15}>Название</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {campaigns.map(item => (
                <Table.Row key={item.id}>
                  <Table.Cell>{item.id}</Table.Cell>
                  <Table.Cell>{item.name}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Fragment>
      )}
      {!campaigns.length && !isFetching && (
        <Message info icon="frown outline" content="Ни одной кампаний не найдено." />
      )}
    </Dimmer.Dimmable>
  </ContentContainer>
)

PageLayout.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
    excludeTemplates: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  isFetching: PropTypes.bool.isRequired,
  campaigns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default PageLayout
