export const UNRESOLVED = 'unresolved'
export const PENDING = 'pending'
export const ADDED = 'added'
export const FAILED = 'failed'
export const MERGED = 'merged'
export const DUPLICATE = 'duplicate'
export const CONFLICT = 'conflict'
export const BROKEN_GROUP = 'broken_group'
export const EXCLUDED = 'excluded'

export const list = () => [
  UNRESOLVED,
  PENDING,
  ADDED,
  FAILED,
  MERGED,
  DUPLICATE,
  CONFLICT,
  BROKEN_GROUP,
  EXCLUDED,
]

export const translate = status => {
  switch (status) {
    case UNRESOLVED:
      return 'группа-получатель не подобрана'
    case PENDING:
      return 'доступно для выгрузки'
    case ADDED:
      return 'добавлен'
    case FAILED:
      return 'ошибка'
    case MERGED:
      return 'объединен'
    case DUPLICATE:
      return 'дубль'
    case CONFLICT:
      return 'конфликт'
    case BROKEN_GROUP:
      return 'группа-получатель с ошибками'
    case EXCLUDED:
      return 'исключено по релевантности'
    default:
      return status
  }
}
