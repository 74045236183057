export const PENDING = 'pending'
export const IMPORTING = 'importing'
export const IMPORT_FAILED = 'import_failed'
export const IMPORT_PAUSE = 'import_pause'
export const IMPORTED = 'imported'
export const RUNNING = 'running'
export const FAILED = 'failed'
export const PAUSE = 'pause'
export const COMPLETED = 'completed'
export const SYNC_PENDING = 'sync_pending'
export const SYNCING = 'syncing'
export const SYNC_FAILED = 'sync_failed'
export const SYNCED = 'synced'
export const CANCELED = 'canceled'

const translateMap = {
  [PENDING]: 'Ожидает в очереди…',
  [IMPORTING]: 'Загружаются данные из Директа…',
  [IMPORT_FAILED]: 'При загрузке данных из Директа возникла ошибка. Свяжитесь с техподдержкой',
  [IMPORT_PAUSE]: 'В очереди для загрузки данных из Директа (перезапуск)…',
  [IMPORTED]: 'Данные из Директа загружены. Ожидает в очереди для перегруппировки…',
  [RUNNING]: 'Выполняется перегруппировка…',
  [FAILED]: 'При перегруппировке возникла ошибка. Свяжитесь с техподдержкой',
  [PAUSE]: 'В очереди на проверку (перезапуск)…',
  [COMPLETED]: 'Перегруппировка завершена',
  [SYNC_PENDING]: 'В очереди для выгрузки изменений в Директ…',
  [SYNCING]: 'Идёт выгрузка изменений в Директ…',
  [SYNC_FAILED]: 'При выгрузке изменений в Директ возникла ошибка. Свяжитесь с техподдержкой',
  [SYNCED]: 'Все изменения выгружены в Директ',
  [CANCELED]: 'Задача отменена',
}

export const list = () => Object.keys(translateMap)

export const translate = status => translateMap[status] || status

export const canSync = status => status === COMPLETED

export const allowReport = status =>
  status === COMPLETED ||
  status === SYNC_PENDING ||
  status === SYNC_FAILED ||
  status === SYNCING ||
  status === SYNCED

export const inProgress = status =>
  status === PENDING ||
  status === IMPORTING ||
  status === IMPORT_PAUSE ||
  status === RUNNING ||
  status === PAUSE ||
  status === SYNCING
