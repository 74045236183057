import { all, call, put, takeEvery } from 'redux-saga/effects'
import { requestFailure, requestSuccess } from '../actions'
import * as types from './constants'
import * as api from '../../api'

export function* fetchCampaigns({ projectId, taskId }) {
  try {
    const payload = yield call(api.fetchCampaigns, projectId, taskId)
    yield put(
      requestSuccess(types.FETCH_LIST_SUCCESS, payload, {
        taskId,
        projectId,
      }),
    )
  } catch (err) {
    yield put(
      requestFailure(types.FETCH_LIST_FAILURE, err, {
        taskId,
        projectId,
      }),
    )
  }
}

function* watchFetchCampaigns() {
  yield takeEvery(types.FETCH_LIST_REQUEST, fetchCampaigns)
}

export function* campaignsSaga() {
  yield all([watchFetchCampaigns()])
}
