import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import styled from 'styled-components/macro'
import { Button, Divider, Form, Icon, Message } from 'semantic-ui-react'
import { ErrorMessage, InputReduxForm } from 'pannier/form'
import { getSubmitError } from '../../../../../utils/form'
import { TEXT_COLOR_MUTED } from '../../../../../styles'
import ExcludeTemplatesField from './ExcludeTemplatesField'

const StyledForm = styled(Form)`
  label {
    font-size: 14px !important;
  }

  .note {
    color: ${TEXT_COLOR_MUTED};
  }
`

const NewTaskForm = ({ defaultName, onCancel, handleSubmit, submitting, submitFailed, error }) => (
  <StyledForm onSubmit={handleSubmit}>
    <Field
      name="name"
      component={InputReduxForm}
      label="Название"
      placeholder={defaultName}
      readOnly={submitting}
    />
    <ExcludeTemplatesField
      name="excludeTemplates"
      label="Шаблоны исключений"
      readOnly={submitting}
    />
    <Message size="small" compact icon attached="bottom">
      <Icon name="info circle" color="grey" />
      <Message.Content>
        <p>
          Рекламная кампания будет исключена, если будет соответствовать хотя бы одному шаблону
          (поиск осуществляется по номеру и названию кампании).
        </p>
        <p>
          Шаблон — словосочетание, где игнорируются все символы, кроме букв русского или английского
          алфавита, цифр.
        </p>
        <p>
          <strong>Пример 1:</strong> Исключение всех РК, в названии которых содержится <em>сети</em>{' '}
          или <em>рся</em>:
        </p>
        <blockquote>
          Сети
          <br />
          РСЯ
        </blockquote>
        <p>
          <strong>Пример 2:</strong> Исключение всех РК, в названии которых содержится{' '}
          <em>москва</em> и <em>рся</em>:
        </p>
        <blockquote>Москва рся</blockquote>
        <p>
          <strong>Пример 3:</strong> Исключение всех РК с номерами, в которые входят{' '}
          <em>76383475</em> и <em>78763345</em>:
        </p>
        <blockquote>
          76383475
          <br />
          78763345
        </blockquote>
        <p>
          <strong>Пример 4:</strong> Исключение всех РК, в названии или номерах которых содержатся{' '}
          <em>поиск</em> и <em>123</em> и <em>456</em>:
        </p>
        <blockquote>поиск 123.456</blockquote>
        <p className="note">
          Выражение будет трансформировано в 
          <code>
            поиск <strong>И</strong> 123 <strong>И</strong> 456
          </code>
        </p>
      </Message.Content>
    </Message>
    <Divider />
    <ErrorMessage error={getSubmitError(error, submitFailed)} padded="bottom" />
    <Button positive content="Сохранить" loading={submitting} disabled={submitting} />
    <Button onClick={onCancel} content="Отмена" disabled={submitting} />
  </StyledForm>
)

NewTaskForm.defaultProps = {
  defaultName: null,
  error: null,
}

NewTaskForm.propTypes = {
  defaultName: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  error: PropTypes.string,
}

export default NewTaskForm
