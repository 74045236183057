import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import * as types from './constants'
import {
  makeMergeEntities,
  makeUpdateEntity,
  mergeIds,
  resetEntities,
  resetIds,
  setFalse,
  setTrue,
} from '../actionHandlers'
import { SYNC_PENDING } from '../../constants/taskStatuses'

export * from './actions'
export * from './constants'
export * from './selectors'
export { tasksSaga } from './sagas'

const mergeTasks = makeMergeEntities('tasks')
const getTaskId = action => action.taskId
const makeUpdateTask = data => makeUpdateEntity(getTaskId, data)
const unsetTaskFetching = makeUpdateTask({ isFetching: false })
const unsetTaskUpdating = makeUpdateTask({ isUpdating: false })

export default combineReducers({
  byId: handleActions(
    {
      [types.FETCH_LIST_REQUEST]: resetEntities,
      [types.FETCH_LIST_SUCCESS]: mergeTasks,
      [types.FETCH_TASK_REQUEST]: makeUpdateTask({ isFetching: true }),
      [types.FETCH_TASK_SUCCESS]: (state, action) =>
        unsetTaskFetching(mergeTasks(state, action), action),
      [types.FETCH_TASK_FAILURE]: unsetTaskFetching,
      [types.CREATE_SUCCESS]: mergeTasks,
      [types.ENABLE_SYNC_REQUEST]: makeUpdateTask({ isSyncing: true }),
      [types.ENABLE_SYNC_SUCCESS]: makeUpdateTask(() => ({
        isSyncing: false,
        status: SYNC_PENDING,
        updatedAt: new Date().toISOString(),
      })),
      [types.ENABLE_SYNC_FAILURE]: makeUpdateTask({ isSyncing: false }),
      [types.UPDATE_TASK_REQUEST]: makeUpdateTask({ isUpdating: true }),
      [types.UPDATE_TASK_SUCCESS]: (state, action) =>
        unsetTaskUpdating(mergeTasks(state, action), action),
      [types.UPDATE_TASK_FAILURE]: unsetTaskUpdating,
    },
    {},
  ),
  ids: handleActions(
    {
      [types.FETCH_LIST_REQUEST]: resetIds,
      [types.FETCH_LIST_SUCCESS]: mergeIds,
      [types.CREATE_SUCCESS]: mergeIds,
    },
    [],
  ),
  nextPageOffset: handleActions(
    {
      [types.FETCH_LIST_SUCCESS]: (state, action) => action.nextPageOffset,
    },
    null,
  ),
  total: handleActions(
    {
      [types.FETCH_LIST_SUCCESS]: (state, action) => action.meta.total,
    },
    0,
  ),
  isFetching: handleActions(
    {
      [types.FETCH_LIST_REQUEST]: setTrue,
      [types.FETCH_LIST_SUCCESS]: setFalse,
      [types.FETCH_LIST_FAILURE]: setFalse,
    },
    false,
  ),
})
