import omit from 'lodash/omit'
import { ApiError } from '@airmedia/api-utils'
import rollbar from '../rollbar'

const logError = (err, context = {}) => {
  const message = context.message || err.message || 'Uncaught error'
  let ctx = omit(context, 'message')

  if (err instanceof ApiError) {
    ctx = {
      status: err.status,
      payload: err.payload,
      ...ctx,
    }
  }

  return rollbar.error(message, err, ctx)
}

export default logError
