import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { uniqueId } from 'lodash'
import { Icon } from 'semantic-ui-react'
import * as relevances from '../../../../../constants/relevances'
import Tooltip from '../../../../components/Tooltip'

const colorByRelevance = relevance => {
  switch (relevance) {
    case relevances.RELEVANCE_LOW:
      return 'red'
    case relevances.RELEVANCE_NORMAL:
      return 'yellow'
    case relevances.RELEVANCE_HIGH:
      return 'green'
    default:
      return null
  }
}

const capitalizeFirstLetter = str => `${str.charAt(0).toUpperCase()}${str.slice(1)}`

const description = relevance =>
  `${capitalizeFirstLetter(relevances.translate(relevance))} релевантность`

const RelevanceScore = ({ relevance }) => {
  const tooltipId = uniqueId('relevance-score')
  return (
    <Fragment>
      <Icon
        name="star"
        color={colorByRelevance(relevance)}
        data-for={tooltipId}
        data-tip={description(relevance)}
      />
      <Tooltip id={tooltipId} />
    </Fragment>
  )
}

RelevanceScore.propTypes = {
  relevance: PropTypes.oneOf(relevances.list()).isRequired,
}

export default RelevanceScore
