import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'semantic-ui-react'
import Tooltip from '../../components/Tooltip'
import ConfirmPopup from '../../components/ConfirmPopup'

const SyncButton = ({ isSyncing, disabled, onClick }) => (
  <Fragment>
    <ConfirmPopup
      trigger={
        <Button
          basic
          positive
          content="Синхронизировать"
          loading={isSyncing}
          disabled={disabled}
          data-for="sync-btn"
          data-tip="Выгрузить изменения в Я.Директ"
        />
      }
      header="Подтверждение"
      confirm={onClick}
      position="top center"
      wide
      basic
    >
      <p>
        В Яндекс.Директ могут быть добавлены новые и/или изменены текущие ключевые фразы. К названию
        групп с «мало показов» будет добавлена специальная пометка, фразы в них будут удалены,
        а объявления перемещены в архив.
      </p>
      <p>Автоматически откатить изменения будет невозможно. Всё равно продолжить?</p>
    </ConfirmPopup>
    <Tooltip id="sync-btn" place="left" />
  </Fragment>
)

SyncButton.defaultProps = {
  disabled: true,
}

SyncButton.propTypes = {
  disabled: PropTypes.bool,
  isSyncing: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default SyncButton
