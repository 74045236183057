import { makeTrans } from 'pannier'

export default makeTrans({
  'form.submit.error': 'Пожалуйста, исправьте ошибки и повторите отправку данных.',
  'form.submit.unk_error':
    'Извините, при обработке формы возникла неизвестная ошибка. Повторите попытку и обратитесь в службу поддержки, если ошибка сохранится.',
  'form.submit.error_4xx': ({ status }) =>
    `Не удалось обработать форму, сервер вернул ответ с кодом: ${status}`,
  'form.submit.error_5xx': ({ status }) =>
    `Сервер временно недоступен (${status}). Пожалуйста, попробуйте отправить данные позже. Если ошибка сохранится, обратитесь в службу поддержки.`,

  'form.error.exclude_templates': ({ value, code, params }) => {
    if (code === 'min_length') {
      return `Шаблон "${value}" недопустим. Минимальная длина шаблона: ${params.limit}`
    }
    return `Шаблон "${value}" недопустим`
  },
})
